import { useMemo } from 'react';

import { Box, Button } from '@mui/material';
import {
  gridPageSelector,
  gridPaginationModelSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

interface CustomPaginationProps {
  disablePaginationButtons?: boolean | null;
}

export function CustomPagination({ disablePaginationButtons }: CustomPaginationProps) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);

  const pageCount = useMemo(
    () => Math.ceil(rowCount / (paginationModel.pageSize || 1)),
    [rowCount, paginationModel.pageSize],
  );

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        sx={{ mr: 1 }}
        disabled={page === 0}
        onClick={() => apiRef.current?.setPage(page - 1)}
        data-testid="PaginationPreviousButton"
      >
        Previous
      </Button>
      <Button
        variant="outlined"
        size="small"
        disabled={disablePaginationButtons || page === pageCount - 1}
        onClick={() => apiRef.current?.setPage(page + 1)}
        data-testid="PaginationNextButton"
      >
        Next
      </Button>
    </Box>
  );
}
