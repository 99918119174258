import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { CustomerCriteria } from '@one/api-models/lib/Admin/PaymentPlan/CustomerCriteria';
import { DetailsCriteria } from '@one/api-models/lib/Admin/PaymentPlan/DetailsCriteria';
import { DetailsResponse } from '@one/api-models/lib/Admin/PaymentPlan/DetailsResponse';
import { FilterResponse } from '@one/api-models/lib/Admin/PaymentPlan/FilterResponse';
import { ListPaymentMethodsCriteria } from '@one/api-models/lib/Sales/Payment/PaymentMethod/ListPaymentMethodsCriteria';
import { ListPaymentMethodsResponse } from '@one/api-models/lib/Sales/Payment/PaymentMethod/ListPaymentMethodsResponse';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand } from 'slices/applicationDataSlice';

export const useRetrievePaymentPlanList = (memberKey: string | undefined) => {
  const { api } = useApiHelpers();
  const { addApiError } = useToastMessage();
  const activeBrand = useSelector(selectActiveBrand);

  return useQuery<FilterResponse, ApiError>(
    ['useRetrievePaymentPlanList', memberKey],
    () =>
      api.payment.retrievePaymentPlanList({
        brandKey: activeBrand?.key ?? '',
        memberKey,
        statuses: [],
      } as CustomerCriteria),
    {
      enabled: !!memberKey,
      keepPreviousData: false,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onError: (error) => addApiError(error),
    },
  );
};

export const useRetrievePaymentPlanDetails = (paymentPlanId: string | number | null) => {
  const { api } = useApiHelpers();
  const { addApiError } = useToastMessage();
  const activeBrand = useSelector(selectActiveBrand);

  return useQuery<DetailsResponse, ApiError>(
    ['useRetrievePaymentPlanDetails', paymentPlanId],
    () =>
      api.payment.retrievePaymentPlanDetails({
        brandKey: activeBrand?.key ?? '',
        paymentPlanId,
      } as DetailsCriteria),
    {
      enabled: !!paymentPlanId,
      keepPreviousData: false,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onError: (error) => addApiError(error),
    },
  );
};

export const useRetrievePaymentMethodList = (
  memberKey: string | undefined,
  enableQuery: boolean,
  paymentPlanId?: number,
) => {
  const { api } = useApiHelpers();
  const { addApiError } = useToastMessage();

  return useQuery<ListPaymentMethodsResponse, ApiError>(
    ['useRetrievePaymentMethodList', memberKey, paymentPlanId, enableQuery],
    () =>
      api.payment.retrievePaymentMethodList({
        customerKey: memberKey,
        paymentPlanId,
      } as ListPaymentMethodsCriteria),
    {
      enabled: !!memberKey && enableQuery,
      keepPreviousData: false,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onError: (error) => addApiError(error),
    },
  );
};
