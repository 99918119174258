import { FC, RefObject, useEffect, useState } from 'react';
import { get } from 'lodash';

import { Box, Skeleton } from '@mui/material';
import { CybersourceGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/CybersourceGatewayConfiguration';
import { Shift4GatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/Shift4GatewayConfiguration';
import { StripeGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/StripeGatewayConfiguration';

import { StripeWrapper } from 'common';
import { AddPaymentMethodCybersourceComponent } from 'common/payment/AddPaymentMethodCybersourceComponent';
import {
  AddPaymentMethodStripeComponent,
  AddPaymentMethodStripeComponentProps,
} from 'common/payment/AddPaymentMethodStripeComponent';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { PaymentSetupType, usePaymentSetup } from 'components/hooks/usePaymentSetup';
import { PaymentProviderType } from 'models/PaymentProvider';

import { PaymentDetailsCybersource } from './PaymentDetailsCybersource';
import { PaymentDetailsShift4 } from './PaymentDetailsShift4';
import { PaymentDetailsStripe } from './PaymentDetailsStripe';

interface PaymentDetailsStripeWrappedProps {
  paymentGatewayConfiguration: StripeGatewayConfiguration;
  formRef: RefObject<HTMLFormElement>;
}

const PaymentDetailsStripeWrapped: FC<PaymentDetailsStripeWrappedProps> = ({
  paymentGatewayConfiguration,
  formRef,
}) => (
  <StripeWrapper paymentGatewayConfiguration={paymentGatewayConfiguration}>
    <PaymentDetailsStripe formRef={formRef} />
  </StripeWrapper>
);

interface AddPaymentMethodStripeComponentWrappedProps extends AddPaymentMethodStripeComponentProps {
  paymentGatewayConfiguration: StripeGatewayConfiguration;
}

const PaymentPlanStripePaymentsSectionWrapped: React.FC<AddPaymentMethodStripeComponentWrappedProps> = ({
  paymentGatewayConfiguration,
  ...props
}) => (
  <StripeWrapper paymentGatewayConfiguration={paymentGatewayConfiguration}>
    <AddPaymentMethodStripeComponent {...props} paymentGatewayConfiguration={paymentGatewayConfiguration} />
  </StripeWrapper>
);

type PaymentComponentType = React.FC<any>;

type PaymentComponentMap = {
  [key: string]: PaymentComponentType;
};

interface PaymentProviderProps {
  type: PaymentSetupType;
  formRef: RefObject<HTMLFormElement>;
  // Unknown props for conditionally rendered component
  [x: string]: any;
}

export const PaymentProvider: React.FC<PaymentProviderProps> = ({ type, formRef, ...props }) => {
  const [paymentGatewayConfiguration, setPaymentGatewayConfiguration] = useState<
    StripeGatewayConfiguration | Shift4GatewayConfiguration | CybersourceGatewayConfiguration
  >();
  const [isLoadingConfiguration, setIsLoadingConfiguration] = useState(false);
  const paymentType = get(paymentGatewayConfiguration, '$type');

  const paymentSetupMutation = usePaymentSetup(type, setIsLoadingConfiguration, setPaymentGatewayConfiguration);

  useEffect(() => {
    setIsLoadingConfiguration(true);
    paymentSetupMutation.mutateAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchConfiguration = () => {
    setIsLoadingConfiguration(true);
    paymentSetupMutation.mutateAsync();
  };

  const programSaleComponentMap: PaymentComponentMap = {
    [PaymentProviderType.Stripe]: PaymentDetailsStripeWrapped,
    [PaymentProviderType.Shift4]: PaymentDetailsShift4,
    [PaymentProviderType.Cybersource]: PaymentDetailsCybersource,
  };

  const paymentPlansComponentMap: PaymentComponentMap = {
    [PaymentProviderType.Stripe]: PaymentPlanStripePaymentsSectionWrapped,
    [PaymentProviderType.Cybersource]: AddPaymentMethodCybersourceComponent,
  };

  const componentMap = {
    programSale: programSaleComponentMap,
    paymentPlans: paymentPlansComponentMap,
  };

  // Default payment provider Stripe
  const PaymentComponent =
    paymentType && componentMap[type]
      ? componentMap[type][paymentType] || componentMap[type][PaymentProviderType.Stripe]
      : componentMap[type][PaymentProviderType.Stripe];

  return paymentGatewayConfiguration ? (
    <PaymentComponent
      {...props}
      formRef={formRef}
      paymentGatewayConfiguration={paymentGatewayConfiguration}
      isLoadingConfiguration={isLoadingConfiguration}
      refetchConfiguration={refetchConfiguration}
    />
  ) : (
    <PaymentSkeleton isLoadingConfiguration={isLoadingConfiguration} />
  );
};

const PaymentSkeleton = ({ isLoadingConfiguration }: { isLoadingConfiguration: boolean }) => {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Skeleton variant="rectangular" height={180} sx={{ mt: 1 }} />
      </Box>
      <LoadingScreen open={isLoadingConfiguration} message={'Loading payment details...'} />
    </>
  );
};
