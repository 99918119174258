interface Config {
  adminApiUrl?: string;
  salesApiUrl?: string;
  applicationInsightsKey?: string;
  apiDomain?: string;
  azureADClientId?: string;
  azureADTenantId?: string;
  azureADRedirectUrl?: string;
  azureADAuthority: string;
  agentPortalADClientId?: string;
  agentPortalADTenantId?: string;
  agentPortalADAuthority: string;
  agentPortalSSORedirectUrl?: string;
  agentPortalAppUrl?: string;
  isMaintenanceMode?: boolean;
  isMCMSingleIdentityEnabled: boolean;
  googleMapsKey?: string;
  prodMode?: boolean;
  matchingTool?: boolean;
  muiProKey?: string;
}

const appConfig: Config = {
  adminApiUrl: process.env.REACT_APP_ADMIN_API_URL,
  salesApiUrl: process.env.REACT_APP_SALES_API_URL,
  applicationInsightsKey: process.env.REACT_APP_APPLICATION_INSIGHTS_IK,
  apiDomain: process.env.REACT_APP_API_DOMAIN,
  azureADClientId: process.env.REACT_APP_AD_CLIENT_ID,
  azureADTenantId: process.env.REACT_APP_AD_TENANT_ID,
  azureADAuthority: process.env.REACT_APP_AD_AUTHORITY || 'https://login.microsoftonline.com',
  azureADRedirectUrl: process.env.REACT_APP_AD_REDIRECT_URL,
  agentPortalADClientId: process.env.REACT_APP_AGENT_PORTAL_AD_CLIENT_ID,
  agentPortalADTenantId: process.env.REACT_APP_AGENT_PORTAL_AD_TENANT_ID,
  agentPortalADAuthority:
    process.env.REACT_APP_AGENT_PORTAL_AD_AUTHORITY ||
    'https://oneagencytest.b2clogin.com/oneagencytest.onmicrosoft.com',
  agentPortalSSORedirectUrl: process.env.REACT_APP_AGENT_PORTAL_SSO_LOGOUT_REDIRECT_URL,
  agentPortalAppUrl: process.env.REACT_APP_AGENT_PORTAL_URL,
  isMaintenanceMode: process.env.REACT_APP_IS_MAINTENANCE_MODE === 'true',
  isMCMSingleIdentityEnabled: process.env.REACT_APP_ENABLE_MCM_SINGLE_IDENTITY === 'true',
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  prodMode: process.env.REACT_APP_PROD_MODE === 'true',
  matchingTool: process.env.REACT_APP_HB_MATCHING === 'true',
  muiProKey: process.env.REACT_APP_MUI_PRO_KEY,
};

export default appConfig;
