import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CustomerDetails } from '@one/api-models/lib/Admin/PaymentPlan/CustomerDetails';
import { DepositPlan } from '@one/api-models/lib/Admin/PaymentPlan/DepositPlan';
import { InstallmentPlan } from '@one/api-models/lib/Admin/PaymentPlan/InstallmentPlan';
import { PaymentPlanBase } from '@one/api-models/lib/Admin/PaymentPlan/PaymentPlanBase';
import { PaymentPlanStatus } from '@one/api-models/lib/Admin/PaymentPlan/PaymentPlanStatus';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';
import { CalculateExistingPlanRequest } from '@one/api-models/lib/Sales/Payment/PaymentPlan/CalculateExistingPlanRequest';

import { isBillingDetailsRequiredByPaymentProviderValid } from 'common/payment/utils';
import { Loading } from 'components/_common/Loading';
import { PaymentPlanCustomerSection } from 'components/_common/paymentPlan/PaymentPlanCustomerSection';
import { PaymentPlanNoteSection } from 'components/_common/paymentPlan/PaymentPlanNoteSection';
import { PaymentPlanOrderSection } from 'components/_common/paymentPlan/PaymentPlanOrderSection';
import { PaymentPlanPaymentsSection } from 'components/_common/paymentPlan/PaymentPlanPaymentsSection';
import { PaymentPlanProductSection } from 'components/_common/paymentPlan/PaymentPlanProductSection';
import { PaymentPlanScheduleSection } from 'components/_common/paymentPlan/PaymentPlanScheduleSection';
import { PaymentPlanSummary } from 'components/_common/paymentPlan/PaymentPlanSummary';
import { SideModalContainer } from 'components/_common/sideModal/SideModalContainer';
import { SideModalContentWrapper } from 'components/_common/sideModal/SideModalContentWrapper';
import { Transition } from 'components/_common/sideModal/SideModalTransition';
import { useRetrievePaymentMethodList, useRetrievePaymentPlanDetails } from 'components/hooks/paymentHooks';
import { useFormat } from 'components/hooks/useFormat';
import { usePaymentPlan } from 'components/hooks/usePaymentPlan';
import { BillingDetailsDialog } from 'components/views/sales/BillingDetailsDialog';
import { PaymentProviderList } from 'models/PaymentProvider';
import {
  selectCalculatedPaymentPlan,
  selectIsLoadingCalculatePaymentPlan,
  selectIsLoadingUpdatePaymentPlan,
  selectUpdatedPaymentPlan,
} from 'slices/paymentDataSlice';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedPaymentPlanId: string | number | null;
  handleUpdatePaymentPlanSuccess: () => void;
  memberId: string;
  testId: string;
  defaultBillingDetails: BillingDetails;
}
export interface PaymentMethodOption {
  code: string;
  label: string;
  isDefaultPaymentMethod: boolean;
  expirationMonth?: number;
  expirationYear?: number;
  billingDetails?: BillingDetails;
}

export const minAdHocAmount = 1;

export type UpdatePaymentPlanForm = {
  adhocPaymentAmount?: string | null | undefined;
  paymentMethodReference?: string;
  adhocPaymentMethodReference?: string;
  intervalCount?: string | null | undefined;
  endTerm?: Date | null | undefined;
};

const validationSchema: yup.SchemaOf<UpdatePaymentPlanForm> = yup.object().shape({
  adhocPaymentAmount: yup.string().trim(),
  paymentMethodReference: yup.string().trim(),
  adhocPaymentMethodReference: yup.string().trim(),
  intervalCount: yup.string().trim(),
  endTerm: yup.date().nullable().typeError('Invalid Date Format.'),
});

export const EditPaymentPlanDialog = (props: Props) => {
  const {
    open,
    onClose,
    selectedPaymentPlanId,
    handleUpdatePaymentPlanSuccess,
    memberId,
    testId,
    defaultBillingDetails,
  } = props;
  const { currentZonedDateToISODate } = useFormat();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line @typescript-eslint/no-unused-vars

  const { performCalculatePaymentPlan, performUpdatePaymentPlan } = usePaymentPlan();

  const isLoadingCalculatePaymentPlan = useSelector(selectIsLoadingCalculatePaymentPlan);
  const calculatedPaymentPlan = useSelector(selectCalculatedPaymentPlan);
  const isLoadingUpdatePaymentPlan = useSelector(selectIsLoadingUpdatePaymentPlan);
  const updatedPaymentPlan = useSelector(selectUpdatedPaymentPlan);

  const [togglePreview, setTogglePreview] = useState<boolean>(true);
  const [previewData, setPreviewData] = useState<PaymentPlanBase | DepositPlan | InstallmentPlan | undefined>(
    undefined,
  );
  const [paymentPlanDetails, setPaymentPlanDetails] = useState<
    PaymentPlanBase | DepositPlan | InstallmentPlan | undefined
  >(undefined);
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails | undefined>(undefined);
  const [paymentPlanId, setPaymentPlanId] = useState<string | number | null>(selectedPaymentPlanId);
  const [enteredAdhocPaymentAmount, setEnteredAdhocPaymentAmount] = useState<string | undefined>('');
  const [selectedAdhocPaymentMethodReference, setSelectedAdhocPaymentMethodReference] = useState<
    PaymentMethodOption | undefined
  >(undefined);
  const [selectedPaymentMethodReference, setSelectedPaymentMethodReference] = useState<PaymentMethodOption | undefined>(
    undefined,
  );
  const [preferredPaymentMethodListOptions, setPreferredPaymentMethodListOptions] = useState<PaymentMethodOption[]>([]);
  const [additionalPaymentMethodListOptions, setAdditionalPaymentMethodListOptions] = useState<PaymentMethodOption[]>(
    [],
  );
  const [allowPreferredPaymentMethod, setAllowPreferredPaymentMethod] = useState<boolean>(false);

  const { isFetching: isFetchingPaymentPlanDetailsData, data: paymentPlanDetailsData } =
    useRetrievePaymentPlanDetails(paymentPlanId);
  const [billingDetailsDialogOpen, setBillingDetailsDialogOpen] = useState<boolean>(false);
  const [validateBillingDetailsOnOpen, setValidateBillingDetailsOnOpen] = useState<boolean>(false);
  const [billingDetails, setBillingDetails] = useState<BillingDetails | undefined>(undefined);

  const {
    isFetching: isFetchingPreferredPaymentMethodList,
    data: preferredPaymentMethodListData,
    refetch: refetchPreferredPaymentMethodList,
  } = useRetrievePaymentMethodList(memberId, allowPreferredPaymentMethod, Number(paymentPlanId));

  const {
    isFetching: isFetchingAdditionalPaymentMethodList,
    data: additionalPaymentMethodListData,
    refetch: refetchAdditionalPaymentMethodList,
  } = useRetrievePaymentMethodList(memberId, true);

  const isFetchingPaymentMethodList = isFetchingPreferredPaymentMethodList || isFetchingAdditionalPaymentMethodList;

  const handleAddPaymentMethod = (paymentMethod: PaymentMethod) => {
    const paymentMethodOption = {
      code: paymentMethod.paymentMethodId.toString(),
      label: `${mapGatewayIdentifierLabel(paymentMethod.gatewayIdentifier)} \n **** **** **** ${
        paymentMethod.description
      }`,
      isDefaultPaymentMethod: false,
      expirationMonth: paymentMethod.expirationMonth,
      expirationYear: paymentMethod.expirationYear,
      billingDetails: paymentMethod.billingDetails,
    };

    setAdditionalPaymentMethodListOptions([...additionalPaymentMethodListOptions, { ...paymentMethodOption }]);
    setPreferredPaymentMethodListOptions([...preferredPaymentMethodListOptions, { ...paymentMethodOption }]);

    setBillingDetails(paymentMethod.billingDetails);
    setValue('adhocPaymentMethodReference', paymentMethod.paymentMethodId.toString());
    setSelectedAdhocPaymentMethodReference(paymentMethodOption);
  };

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<UpdatePaymentPlanForm>({
    resolver: yupResolver(validationSchema),
  });

  const isInstallmentPlan = useCallback((planDetails: PaymentPlanBase | DepositPlan | InstallmentPlan | undefined) => {
    if (!planDetails) return;

    return 'recurringInterval' in planDetails && 'intervalCount' in planDetails;
  }, []);

  const setDefaultValues = useCallback(
    (planDetails: PaymentPlanBase | DepositPlan | InstallmentPlan | undefined) => {
      setValue('adhocPaymentAmount', '');

      if (isInstallmentPlan(planDetails)) {
        setValue('intervalCount', '');
      } else {
        const endTerm = planDetails ? planDetails.paymentPlanEndDate : undefined;

        setValue('endTerm', endTerm);
      }
    },
    [isInstallmentPlan, setValue],
  );

  useEffect(() => {
    if (preferredPaymentMethodListData && paymentPlanDetails) {
      const preferredFormattedPaymentMethods = preferredPaymentMethodListData?.paymentMethods?.map(
        (i: PaymentMethod) => ({
          code: i.paymentMethodId.toString(),
          label: `${mapGatewayIdentifierLabel(i.gatewayIdentifier)} \n **** **** **** ${i.description}`,
          isDefaultPaymentMethod: i.paymentMethodId === paymentPlanDetails?.paymentMethodId,
          expirationMonth: i.expirationMonth,
          expirationYear: i.expirationYear,
        }),
      );

      setPreferredPaymentMethodListOptions(preferredFormattedPaymentMethods);

      const defaultPaymentMethod = preferredFormattedPaymentMethods.find(
        (i: PaymentMethodOption) => Number(i.code) === paymentPlanDetails?.paymentMethodId,
      );

      if (defaultPaymentMethod) {
        setValue('paymentMethodReference', defaultPaymentMethod?.code);
        setSelectedPaymentMethodReference(defaultPaymentMethod);
      }
    }
  }, [preferredPaymentMethodListData, paymentPlanDetails, setValue]);

  useEffect(() => {
    if (additionalPaymentMethodListData && paymentPlanDetails) {
      const additionalFormattedPaymentMethods = additionalPaymentMethodListData?.paymentMethods?.map(
        (i: PaymentMethod) => ({
          code: i.paymentMethodId.toString(),
          label: `${mapGatewayIdentifierLabel(i.gatewayIdentifier)} \n **** **** **** ${i.description}`,
          isDefaultPaymentMethod: i.paymentMethodId === paymentPlanDetails?.paymentMethodId,
          expirationMonth: i.expirationMonth,
          expirationYear: i.expirationYear,
          billingDetails: i.billingDetails,
        }),
      );
      setAdditionalPaymentMethodListOptions(additionalFormattedPaymentMethods);

      const defaultPaymentMethod = additionalFormattedPaymentMethods.find(
        (i: PaymentMethodOption) => Number(i.code) === paymentPlanDetails?.paymentMethodId,
      );

      if (defaultPaymentMethod) {
        setBillingDetails(defaultPaymentMethod.billingDetails);
        setValue('adhocPaymentMethodReference', defaultPaymentMethod?.code);
        setSelectedAdhocPaymentMethodReference(defaultPaymentMethod);
      }
    }
  }, [additionalPaymentMethodListData, paymentPlanDetails, setValue]);

  useEffect(() => {
    if (!isFetchingPaymentPlanDetailsData && paymentPlanDetailsData) {
      setPreviewData(undefined);
      setPaymentPlanDetails(paymentPlanDetailsData.paymentPlan);
      setDefaultValues(paymentPlanDetailsData.paymentPlan);
      setCustomerDetails(paymentPlanDetailsData.customerDetails);
      setAllowPreferredPaymentMethod(paymentPlanDetailsData.allowPreferredPaymentMethod);
    }
  }, [paymentPlanDetailsData, isFetchingPaymentPlanDetailsData, setDefaultValues]);

  useEffect(() => {
    if (calculatedPaymentPlan) {
      setPreviewData(calculatedPaymentPlan.plan as DepositPlan | InstallmentPlan);
    }
  }, [calculatedPaymentPlan]);

  useEffect(() => {
    if (!isLoadingUpdatePaymentPlan && updatedPaymentPlan) {
      setPreviewData(undefined);
      setPaymentPlanDetails(updatedPaymentPlan);
      setDefaultValues(updatedPaymentPlan);
      setEnteredAdhocPaymentAmount('');
      setPaymentPlanId(updatedPaymentPlan.paymentPlanId);
      setSearchParams({ paymentPlanId: updatedPaymentPlan.paymentPlanId.toString() });
    }
  }, [updatedPaymentPlan, isLoadingUpdatePaymentPlan, setDefaultValues, setSearchParams]);

  const handleTogglePreview = () => {
    setTogglePreview(!togglePreview);
  };

  const mapGatewayIdentifierLabel = (gatewayIdentifier: string) => {
    const provider = PaymentProviderList.find((provider) => gatewayIdentifier.includes(provider));
    return provider ? provider : 'Unknown';
  };

  const getSubmitButtonLabel = () => {
    const adhocPaymentAmount = getValues('adhocPaymentAmount');
    const intervalCount = getValues('intervalCount');
    const endTerm = getValues('endTerm');
    const paymentMethodReference = getValues('paymentMethodReference');

    let amountLabel = '';
    let scheduleLabel = '';
    let preferredLabel = '';

    if (
      adhocPaymentAmount &&
      paymentPlanDetails &&
      parseFloat(adhocPaymentAmount) >= minAdHocAmount &&
      parseFloat(adhocPaymentAmount) <= paymentPlanDetails?.balance?.amount
    ) {
      amountLabel = 'Charge Card & ';
    }

    if (!!intervalCount || !!endTerm) {
      scheduleLabel = ' Schedule';
    }
    if (Number(paymentMethodReference) !== paymentPlanDetails?.paymentMethodId && allowPreferredPaymentMethod) {
      preferredLabel = ' Preferred Payment Method';
    }

    return `${amountLabel}Update Payment Plan${scheduleLabel}${
      !!scheduleLabel && !!preferredLabel ? ' And' : ''
    }${preferredLabel}`;
  };

  const getUpdatePaymentPlanPayload = () => {
    if (!paymentPlanId || !paymentPlanDetails) return null;

    const adhocPaymentAmount = getValues('adhocPaymentAmount');
    const adhocPaymentMethodReference = getValues('adhocPaymentMethodReference');
    const paymentMethodReference = getValues('paymentMethodReference');
    const intervalCount = getValues('intervalCount');
    const endTerm = getValues('endTerm');

    let adhocPaymentAmountPayload = null;
    let intervalCountPayload = {};
    let endTermPayload = {};
    let shouldUpdatePlanSchedule = false;

    if (
      adhocPaymentAmount &&
      parseFloat(adhocPaymentAmount) >= minAdHocAmount &&
      parseFloat(adhocPaymentAmount) <= paymentPlanDetails?.balance?.amount
    ) {
      adhocPaymentAmountPayload = {
        adhocPaymentAmount: {
          currency: paymentPlanDetails?.total?.currency ? paymentPlanDetails?.total?.currency : 'USD',
          amount: parseFloat(adhocPaymentAmount),
          isEstimated: false,
        },
        paymentMethodId: adhocPaymentMethodReference ? parseFloat(adhocPaymentMethodReference) : 0,
      };
      shouldUpdatePlanSchedule = true;
    }

    if (intervalCount && parseFloat(intervalCount) > 0) {
      intervalCountPayload = {
        intervalCount: parseFloat(intervalCount),
      };
      shouldUpdatePlanSchedule = true;
    }

    if (endTerm) {
      endTermPayload = {
        endTerm: currentZonedDateToISODate(new Date(endTerm)),
      };
      shouldUpdatePlanSchedule = true;
    }

    return {
      currencyCode: paymentPlanDetails?.total?.currency ? paymentPlanDetails?.total?.currency : 'USD',
      paymentPlanId: parseFloat(paymentPlanId.toString()),
      billingDetails: billingDetails,
      ...(paymentMethodReference &&
        Number(paymentMethodReference) !== paymentPlanDetails?.paymentMethodId && {
          preferredPaymentMethodId: parseFloat(paymentMethodReference),
        }),
      ...(shouldUpdatePlanSchedule && {
        paymentPlanSchedule: {
          ...adhocPaymentAmountPayload,
          ...intervalCountPayload,
          ...endTermPayload,
        },
      }),
    };
  };

  const isFormDirty = () => {
    const adhocPaymentAmount = getValues('adhocPaymentAmount');
    const intervalCount = getValues('intervalCount');
    const endTerm = getValues('endTerm');
    const paymentMethodReference = getValues('paymentMethodReference');

    return (
      (adhocPaymentAmount &&
        paymentPlanDetails &&
        parseFloat(adhocPaymentAmount) >= minAdHocAmount &&
        parseFloat(adhocPaymentAmount) <= paymentPlanDetails?.balance?.amount) ||
      !!intervalCount ||
      !!endTerm ||
      (allowPreferredPaymentMethod && Number(paymentMethodReference) !== paymentPlanDetails?.paymentMethodId)
    );
  };

  const isPaymentPlanEditable = useMemo(() => {
    return (
      paymentPlanDetails &&
      (paymentPlanDetails.paymentPlanStatus === PaymentPlanStatus.Active ||
        paymentPlanDetails.paymentPlanStatus === PaymentPlanStatus.UnPaid ||
        paymentPlanDetails.paymentPlanStatus === PaymentPlanStatus.PastDue ||
        paymentPlanDetails.paymentPlanStatus === PaymentPlanStatus.Canceled)
    );
  }, [paymentPlanDetails]);

  const handleChangeAdhocPaymentMethod = (paymentMethodReference: string | undefined) => {
    const selectedPaymentMethod = additionalPaymentMethodListOptions.find(
      (i: PaymentMethodOption) => i.code === paymentMethodReference,
    );
    if (selectedPaymentMethod) {
      setSelectedAdhocPaymentMethodReference(selectedPaymentMethod);
      setBillingDetails(selectedPaymentMethod.billingDetails);
    }
  };

  const handleChangePaymentMethod = (paymentMethodReference: string | undefined) => {
    const selectedPaymentMethod = preferredPaymentMethodListOptions.find(
      (i: PaymentMethodOption) => i.code === paymentMethodReference,
    );
    if (selectedPaymentMethod) {
      setSelectedPaymentMethodReference(selectedPaymentMethod);
    }
  };

  const handleCalculatePaymentPlan = () => {
    const adhocPaymentAmount = getValues('adhocPaymentAmount');
    const intervalCount = getValues('intervalCount');
    const endTerm = getValues('endTerm');

    const isDirty = isFormDirty();
    const hasValidAdhocPaymentAmount =
      adhocPaymentAmount &&
      paymentPlanDetails &&
      parseFloat(adhocPaymentAmount) >= minAdHocAmount &&
      parseFloat(adhocPaymentAmount) <= paymentPlanDetails?.balance?.amount;
    const hasValidTerm = intervalCount || endTerm;
    const isConditionMet =
      isDirty &&
      ((hasValidTerm && hasValidAdhocPaymentAmount) ||
        (hasValidTerm && !adhocPaymentAmount) ||
        hasValidAdhocPaymentAmount);

    if (isConditionMet) {
      setEnteredAdhocPaymentAmount(adhocPaymentAmount || '');
      const payload = getUpdatePaymentPlanPayload();

      if (payload && payload.paymentPlanSchedule) {
        performCalculatePaymentPlan(payload as CalculateExistingPlanRequest);
      }
    } else {
      setEnteredAdhocPaymentAmount(adhocPaymentAmount || '');
      setPreviewData(undefined);
    }
  };
  const handleChangeBillingDetails = (billingDetails: BillingDetails) => {
    setBillingDetails(billingDetails);
    setBillingDetailsDialogOpen(false);
    setValidateBillingDetailsOnOpen(false);
  };

  const handleSubmitEditPaymentPlan = async () => {
    const payload = getUpdatePaymentPlanPayload();
    if (!isFormDirty() || !payload) return;

    if (isBillingDetailsRequiredByPaymentProviderValid(billingDetails)) {
      setBillingDetailsDialogOpen(true);
      setValidateBillingDetailsOnOpen(true);
      return;
    }

    await performUpdatePaymentPlan(payload);

    if (payload.preferredPaymentMethodId) {
      setPreferredPaymentMethodListOptions(
        preferredPaymentMethodListOptions.map((paymentMethod: PaymentMethodOption) => ({
          ...paymentMethod,
          isDefaultPaymentMethod: Number(paymentMethod.code) === payload?.preferredPaymentMethodId,
        })),
      );
    }

    handleUpdatePaymentPlanSuccess();
    refetchAdditionalPaymentMethodList();
    refetchPreferredPaymentMethodList();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={togglePreview ? 'lg' : 'md'}
      fullWidth
      className={'MuiSideDialog'}
    >
      <DialogTitle
        variant="h4"
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box>Edit Payment Plan</Box>
        <Box>
          <Button
            size="small"
            variant="outlined"
            disableRipple
            onClick={handleTogglePreview}
            sx={{ mr: 2 }}
            data-testid={`${testId}TogglePreviewButton`}
          >
            {togglePreview ? 'Close preview' : 'Show preview'}
          </Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            disableRipple
            size="small"
            data-testid={`${testId}CloseButton`}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      {isFetchingPaymentPlanDetailsData || isLoadingUpdatePaymentPlan ? (
        <Loading isContained />
      ) : (
        <SideModalContainer showPreview={togglePreview}>
          <SideModalContentWrapper>
            {paymentPlanDetailsData && (
              <>
                <DialogContent>
                  <PaymentPlanCustomerSection customerDetails={customerDetails} />
                  <PaymentPlanOrderSection
                    paymentPlanDetails={paymentPlanDetails}
                    handleChangePaymentMethod={handleChangePaymentMethod}
                    paymentMethodListOptions={preferredPaymentMethodListOptions}
                    isFetchingPaymentMethodList={isFetchingPaymentMethodList}
                    allowPreferredPaymentMethod={allowPreferredPaymentMethod}
                    control={control}
                    errors={errors}
                    testId={testId}
                  />
                  <PaymentPlanProductSection paymentPlanDetails={paymentPlanDetails} />
                  <PaymentPlanPaymentsSection
                    control={control}
                    errors={errors}
                    isInstallmentPlan={isInstallmentPlan(paymentPlanDetails)}
                    isPaymentPlanEditable={isPaymentPlanEditable}
                    adhocPaymentAmount={enteredAdhocPaymentAmount}
                    balance={paymentPlanDetails?.balance}
                    selectedPaymentMethod={selectedAdhocPaymentMethodReference?.label}
                    paymentMethodListOptions={additionalPaymentMethodListOptions}
                    isFetchingPaymentMethodList={isFetchingPaymentMethodList}
                    isLoadingCalculatePaymentPlan={isLoadingCalculatePaymentPlan}
                    handleAddPaymentMethod={handleAddPaymentMethod}
                    handleChangePaymentMethod={handleChangeAdhocPaymentMethod}
                    handleCalculatePaymentPlan={handleCalculatePaymentPlan}
                    handleOpenBillingDetailsDialog={() => setBillingDetailsDialogOpen(true)}
                    selectedPaymentMethodBillingDetails={billingDetails}
                    memberId={memberId}
                    defaultBillingDetails={defaultBillingDetails}
                    testId={testId}
                  />
                  <PaymentPlanScheduleSection
                    control={control}
                    errors={errors}
                    isInstallmentPlan={isInstallmentPlan(paymentPlanDetails)}
                    isPaymentPlanEditable={isPaymentPlanEditable}
                    paymentPlanDetails={paymentPlanDetails}
                    previewData={previewData}
                    isLoading={isLoadingCalculatePaymentPlan}
                    handleCalculatePaymentPlan={handleCalculatePaymentPlan}
                    testId={testId}
                  />
                  <PaymentPlanNoteSection />
                </DialogContent>
                <DialogActions>
                  <Button
                    disableRipple
                    variant="contained"
                    type="button"
                    size="small"
                    disabled={
                      !isFormDirty() ||
                      !isInstallmentPlan(paymentPlanDetails) ||
                      !isPaymentPlanEditable ||
                      isLoadingCalculatePaymentPlan
                    }
                    onClick={handleSubmit(handleSubmitEditPaymentPlan)}
                    data-testid={`${testId}SubmitButton`}
                  >
                    {getSubmitButtonLabel()}
                  </Button>
                </DialogActions>
              </>
            )}
          </SideModalContentWrapper>
          {togglePreview && (
            <PaymentPlanSummary
              paymentPlanDetails={paymentPlanDetails}
              previewData={previewData}
              adhocPaymentAmount={enteredAdhocPaymentAmount || '0'}
              isLoading={isLoadingCalculatePaymentPlan}
              isInstallmentPlan={isInstallmentPlan(paymentPlanDetails)}
              selectedPaymentMethod={selectedPaymentMethodReference}
              allowPreferredPaymentMethod={allowPreferredPaymentMethod}
              selectedAdhocPaymentMethod={selectedAdhocPaymentMethodReference}
            />
          )}
          <BillingDetailsDialog
            open={billingDetailsDialogOpen}
            handleClose={() => setBillingDetailsDialogOpen(false)}
            billingDetails={billingDetails}
            handleBillingDetailsChange={handleChangeBillingDetails}
            validateOnOpen={validateBillingDetailsOnOpen}
            testId={testId}
          />
        </SideModalContainer>
      )}
    </Dialog>
  );
};
