import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';

import { SyncAlt } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid-pro';
import { ListingData } from '@one/api-models/lib/Admin/Common/ListingData';
import { AlternativeCurrency } from '@one/api-models/lib/Agency/AlternativeCurrency';
import { AgentListItem } from '@one/api-models/lib/Agency/Team/AgentListItem';
import { LoadCriteria } from '@one/api-models/lib/Agency/Team/LoadCriteria';
import { LoadResponse } from '@one/api-models/lib/Agency/Team/LoadResponse';

import { ApiError } from 'apiAccess/api-client';
import { AgentStatus } from 'components/_common/AgentStatus';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { PageHeader } from 'components/_common/PageHeader';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { useDebouncedValue } from 'components/hooks/useDebounce';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';

import { AssetsTransfer } from './AssetsTransfer';

const defaultPageSize = 25;

export const AgentsList = () => {
  const { formatDate, formatNumber } = useFormat();
  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();
  const { hasPermission } = useAppHelpers();
  const hasTransferPermission = hasPermission(['Agency.Transfer:View']);
  const [transferAssetsModalOpen, setTransferAssetsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedAgentKey, setSelectedAgentKey] = useState<string | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [agents, setAgents] = useState<AgentListItem[] | undefined>(undefined);
  const isLoading = useIsMutating({ mutationKey: 'teamMutation' }) > 0;
  const inputValueDebounced = useDebouncedValue(searchTerm, 300);
  const testIdPrefix = 'AgentsList';

  useEffect(() => {
    teamMutation.mutateAsync({
      $Type: LoadCriteria.$type,
      listingCriteria: {
        skip: page * defaultPageSize,
        take: defaultPageSize,
        searchTerm: searchTerm,
      },
    } as LoadCriteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, inputValueDebounced]);

  const teamMutation = useMutation<LoadResponse, ApiError, LoadCriteria, unknown>(
    (request) => api.agent.loadTeam(request),
    {
      mutationKey: 'teamMutation',
      onSuccess: (value: LoadResponse) => {
        setAgents((value.agentList as ListingData<AgentListItem>).items);
        setTotalRowsCount((value.agentList as ListingData<AgentListItem>).itemCount);
      },
      onError: apiErrorHandler,
    },
  );

  const handleAgentClick = (agent: AgentListItem) => {
    setSelectedAgentKey(agent.agentKey);
    setTransferAssetsModalOpen(true);
  };

  const handleCloseTransferModal = async () => {
    setSelectedAgentKey(undefined);
    setTransferAssetsModalOpen(false);

    await teamMutation.mutateAsync({
      $Type: LoadCriteria.$type,
      listingCriteria: {
        skip: page,
        take: defaultPageSize,
        searchTerm: searchTerm,
      },
    } as LoadCriteria);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Member',
      flex: 2,
      sortable: false,
      renderCell: (params: any) => (
        <Box>
          <Typography variant="subtitle2">{params?.row?.name}</Typography>
          <>{params?.row?.email}</>
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <AgentStatus status={params?.row?.status} />,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <>{params?.row?.roles?.join(', ')}</>,
    },
    {
      field: 'assets',
      headerName: 'Assets',
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => (
        <>
          {params?.row?.alternativeCurrencies && params?.row?.alternativeCurrencies?.length > 0 ? (
            <Box>
              {params?.row?.alternativeCurrencies?.map((altCurrency: AlternativeCurrency, index: number) => (
                <Box key={`AltCurrency${index}`}>{`${formatNumber(altCurrency?.amount)} ${altCurrency?.name}`}</Box>
              ))}
            </Box>
          ) : (
            'n/a'
          )}
        </>
      ),
    },
    {
      field: 'lastLoginDate',
      headerName: 'Last Login',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <>{formatDate(new Date(params?.row?.lastLogin), true, 'MMM dd, yyyy hh:mm aa')}</>,
    },
    {
      field: 'action',
      headerName: '',
      flex: 0.5,
      align: 'right',
      sortable: false,
      renderCell: (params: any) => {
        if (!hasTransferPermission) return null;

        return (
          <Button
            variant="contained"
            size="small"
            title="Transfer Points"
            onClick={() => handleAgentClick(params.row)}
            data-testid={`${testIdPrefix}TransferPointsButton`}
          >
            <SyncAlt />
          </Button>
        );
      },
    },
  ];

  return (
    <Box>
      <LoadingScreen open={isLoading} message={'Loading...'} />
      <PageHeader title="Team" testId={testIdPrefix} />
      <TextField
        name="search"
        placeholder="Filter by name or email..."
        InputLabelProps={{ shrink: true }}
        defaultValue=""
        onChange={(event: any) => {
          setPage(0);
          setSearchTerm(event.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        inputProps={{
          'data-testid': `${testIdPrefix}SearchTextInput`,
        }}
        size="small"
        sx={{ pb: 3 }}
      />
      {!isLoading ? (
        <>
          {agents && agents.length > 0 ? (
            <DataGrid
              rows={agents.map((item: AgentListItem, index) => ({ ...item, id: index }))}
              columns={columns}
              paginationMode="server"
              initialState={{
                pagination: {
                  paginationModel: {
                    page: page,
                    pageSize: defaultPageSize,
                  },
                },
              }}
              rowCount={totalRowsCount}
              onPaginationModelChange={(data: GridPaginationModel) => setPage(data.page)}
              hideFooter={totalRowsCount <= defaultPageSize}
            />
          ) : (
            <Typography variant="body1">No agents available</Typography>
          )}
        </>
      ) : null}
      {selectedAgentKey && (
        <AssetsTransfer
          open={transferAssetsModalOpen}
          recipientAgentKey={selectedAgentKey}
          handleOnClose={handleCloseTransferModal}
          testId={`${testIdPrefix}AssetsTransfer`}
        />
      )}
    </Box>
  );
};
