import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { CompleteAddPaymentMethodRequest } from '@one/api-models/lib/Sales/Payment/PaymentMethod/CompleteAddPaymentMethodRequest';
import { CompleteAddPaymentMethodResponse } from '@one/api-models/lib/Sales/Payment/PaymentMethod/CompleteAddPaymentMethodResponse';
import { InitAddPaymentMethodRequest } from '@one/api-models/lib/Sales/Payment/PaymentMethod/InitAddPaymentMethodRequest';
import { InitAddPaymentMethodResponse } from '@one/api-models/lib/Sales/Payment/PaymentMethod/InitAddPaymentMethodResponse';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';
import { SetupIntent, Stripe, StripeCardNumberElement } from '@stripe/stripe-js';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { setCreatePaymentMethodError, setIsLoadingCreatePaymentMethod } from 'slices/paymentDataSlice';

export const useStripeCreatePayment = () => {
  const dispatch = useDispatch();
  const { api } = useApiHelpers();
  const { apiErrorHandler, showMessage } = useToastMessage();

  // #region Create Payment Method
  const initCreatePaymentMethodMutation = useMutation<
    InitAddPaymentMethodResponse,
    ApiError,
    { memberKey: string; paymentGatewayIdentifier: string; billingDetails: BillingDetails },
    unknown
  >(
    async ({ memberKey, paymentGatewayIdentifier, billingDetails }) => {
      return await api.payment.initCreatePaymentMethod({
        customerKey: memberKey,
        paymentGatewayIdentifier,
        billingDetails,
      } as InitAddPaymentMethodRequest);
    },
    {
      onError: apiErrorHandler,
    },
  );

  const completeCreatePaymentMethodMutation = useMutation<
    CompleteAddPaymentMethodResponse,
    ApiError,
    {
      memberKey: string;
      setupIntent: SetupIntent;
      isPreferredPaymentMethod: boolean;
      paymentGatewayIdentifier: string;
      billingDetails: BillingDetails;
      callback: (paymentMethod: PaymentMethod) => void;
      setAddPaymentModalOpen: (value: any) => void;
    },
    unknown
  >(
    async ({
      memberKey,
      setupIntent,
      isPreferredPaymentMethod,
      paymentGatewayIdentifier,
      billingDetails,
      callback,
      setAddPaymentModalOpen,
    }) => {
      const response = await api.payment.completeCreatePaymentMethod({
        customerKey: memberKey,
        paymentMethodReference: setupIntent?.payment_method,
        isPreferredPaymentMethod,
        paymentGatewayIdentifier,
        billingDetails,
      } as CompleteAddPaymentMethodRequest);
      callback(response.paymentMethod);
      setAddPaymentModalOpen(false);
      return response;
    },
    {
      onSuccess: () => {
        showMessage('Payment method added successfully', 'success');
      },
      onError: apiErrorHandler,
    },
  );

  const performCreatePaymentMethod = async (
    stripe: Stripe | null,
    card: StripeCardNumberElement,
    isDefaultPaymentMethod: boolean,
    paymentGatewayIdentifier: string,
    memberKey: string | undefined,
    billingDetails: BillingDetails,
    callback: (paymentMethod: PaymentMethod) => void,
    setAddPaymentModalOpen: (value: any) => void,
  ) => {
    if (!memberKey) throw new Error('Failed to get memberKey');

    dispatch(setCreatePaymentMethodError(undefined));
    const response = await initCreatePaymentMethodMutation.mutateAsync({
      memberKey,
      paymentGatewayIdentifier,
      billingDetails,
    });
    await performCreatePaymentMethodMutation.mutateAsync({
      stripe,
      memberKey,
      card,
      clientSecret: response?.setupIntent?.clientSecret,
      isDefaultPaymentMethod,
      paymentGatewayIdentifier,
      billingDetails,
      callback,
      setAddPaymentModalOpen,
    });
  };

  const performCompleteCreatePaymentMethod = (
    memberKey: string,
    setupIntent: SetupIntent,
    isPreferredPaymentMethod: boolean,
    paymentGatewayIdentifier: string,
    billingDetails: BillingDetails,
    callback: (paymentMethod: PaymentMethod) => void,
    setAddPaymentModalOpen: (value: any) => void,
  ) => {
    completeCreatePaymentMethodMutation.mutate({
      memberKey,
      setupIntent,
      isPreferredPaymentMethod,
      paymentGatewayIdentifier,
      billingDetails,
      callback,
      setAddPaymentModalOpen,
    });
  };

  // STRIPE Payment Section Begin
  const performCreatePaymentMethodMutation = useMutation<
    any,
    any,
    {
      stripe: Stripe | null;
      memberKey: string;
      card: StripeCardNumberElement;
      clientSecret: string;
      isDefaultPaymentMethod: boolean;
      paymentGatewayIdentifier: string;
      billingDetails: BillingDetails;
      callback: (paymentMethod: PaymentMethod) => void;
      setAddPaymentModalOpen: (value: any) => void;
    }
  >(
    async ({
      stripe,
      memberKey,
      card,
      clientSecret,
      isDefaultPaymentMethod,
      paymentGatewayIdentifier,
      billingDetails,
      callback,
      setAddPaymentModalOpen,
    }) => {
      if (!clientSecret) throw new Error('Failed to get clientSecret');

      if (card == null) throw new Error('Failed to get CardNumberElement');

      if (!stripe) throw new Error('Failed to initialize stripe');

      const { error, setupIntent } = await stripe?.confirmCardSetup(clientSecret, {
        payment_method: {
          card: card,
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      performCompleteCreatePaymentMethod(
        memberKey,
        setupIntent as SetupIntent,
        isDefaultPaymentMethod,
        paymentGatewayIdentifier,
        billingDetails,
        callback,
        setAddPaymentModalOpen,
      );
    },
    {
      onError: (error: any) => {
        dispatch(setCreatePaymentMethodError(error.message));
      },
    },
  );
  // STRIPE Payment Section End
  // #endregion

  useEffect(() => {
    dispatch(
      setIsLoadingCreatePaymentMethod(
        initCreatePaymentMethodMutation.isLoading ||
          completeCreatePaymentMethodMutation.isLoading ||
          performCreatePaymentMethodMutation.isLoading,
      ),
    );
  }, [
    dispatch,
    initCreatePaymentMethodMutation.isLoading,
    completeCreatePaymentMethodMutation.isLoading,
    performCreatePaymentMethodMutation.isLoading,
  ]);

  return {
    performCreatePaymentMethod,
  };
};
