import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { transactionStatusOptions } from 'components/_common/constants/StatusOptions';
import { ActionMenu } from 'components/_common/DataGrid/ActionMenu';
import { dateRangeFilterOperators } from 'components/_common/DataGrid/FilterDateInput';
import { nameFilterOperators } from 'components/_common/DataGrid/FilterNameInput';
import { StatusChip } from 'components/_common/StatusChip';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { Tooltip } from 'styled/Tooltip';

import { getCardLogo } from './common/CardLogo';

interface TransactionColumnsProps {
  handlePaymentDetailsClick: (params: any) => void;
  handleViewCustomerClick: (params: any) => void;
}

const TransactionColumns = ({ handlePaymentDetailsClick, handleViewCustomerClick }: TransactionColumnsProps) => {
  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();

  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const { showMessage } = useToastMessage();

  const columns: GridColDef[] = [
    {
      field: 'partner',
      headerName: 'Partner',
      sortable: false,
      filterable: false,
      width: 70,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (_, row) => row?.customer?.partnerKey,
      renderCell: (params: any) => (
        <Typography sx={{ fontWeight: 600 }}>{params?.row?.customer?.partnerKey}</Typography>
      ),
    },
    {
      field: 'brand',
      headerName: 'Brand',
      sortable: false,
      filterable: false,
      width: 65,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (_, row) => row?.customer?.brandKey,
      renderCell: (params: any) => <Typography sx={{ fontWeight: 600 }}>{params?.row?.customer?.brandKey}</Typography>,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      filterable: false,
      width: 110,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      valueGetter: (_, row) => row?.amount?.amount,
      renderCell: (params: any) => (
        <Typography sx={{ fontWeight: 600, color: 'black' }}>
          {formatCurrency(params?.row?.amount?.amount, params?.row?.amount?.currency)}
        </Typography>
      ),
    },
    {
      field: 'Currency',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 40,
      type: 'string',
      disableColumnMenu: true,
      valueGetter: (_, row) => row?.amount?.currency,
      renderCell: (params: any) => <>{params?.row?.amount?.currency}</>,
    },
    {
      field: 'Status',
      headerName: '',
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: Object.entries(transactionStatusOptions).map(([key, { name }]) => ({
        value: key,
        label: name,
      })),
      width: 156,
      disableColumnMenu: true,
      valueGetter: (_, row) => row?.status,
      renderCell: (params: any) =>
        params.row.status != null ? (
          <Tooltip
            arrow
            title={transactionStatusOptions[params?.row?.status]?.name ?? ''}
            fontSize="0.0775"
            maxWidth="900px"
          >
            <Box width="100%">
              <StatusChip status={transactionStatusOptions[params?.row?.status] || params?.row?.status} />
            </Box>
          </Tooltip>
        ) : (
          <StatusChip status={'-'} />
        ),
    },
    {
      field: 'paymentMethod',
      headerName: 'Payment Method',
      sortable: false,
      filterable: false,
      width: 130,
      disableColumnMenu: true,
      valueGetter: (_, row) => row?.paymentMethod?.cardBrand || '',
      renderCell: (params: any) =>
        params.row.paymentMethod ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {getCardLogo(params?.row?.paymentMethod?.cardBrand)}
            <Typography mb={1.6} ml={-0.5} sx={{ fontSize: '24px' }}>
              ....
            </Typography>
            <Typography sx={{ ml: 0.5 }}>{params?.row?.paymentMethod?.cardLast4Digits}</Typography>
          </Box>
        ) : (
          <Typography>—</Typography>
        ),
    },
    {
      field: 'paymentProvider',
      headerName: 'Payment Provider',
      sortable: false,
      filterable: false,
      width: 130,
      disableColumnMenu: true,
      valueGetter: (_, row) => row?.gatewayName || '',
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params?.row?.gatewayName ? params?.row?.gatewayName : '—'}
        </Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      filterable: false,
      minWidth: 110,
      maxWidth: 150,
      flex: 1,
      type: 'string',
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params?.row?.description ? params?.row?.description : '—'}
        </Typography>
      ),
    },
    {
      field: 'customerEmail',
      headerName: 'Customer Email',
      sortable: false,
      minWidth: 180,
      maxWidth: 310,
      flex: 1,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (_, row) => row?.customer?.email,
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params?.row?.customer?.email}
        </Typography>
      ),
    },
    {
      field: 'createdDateTime',
      headerName: 'Date',
      sortable: false,
      width: 115,
      disableColumnMenu: true,
      filterOperators: dateRangeFilterOperators,
      type: 'date',
      valueGetter: (_, row) => new Date(row?.createdDateTime),
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {formatDate(params?.row?.createdDateTime, true, 'dd MMM, HH:mm')}
        </Typography>
      ),
    },
    {
      field: 'declineReason',
      headerName: 'Decline Reason',
      sortable: false,
      filterable: false,
      width: 118,
      type: 'string',
      disableColumnMenu: true,
      renderCell: () => <>—</>,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableExport: true,
      renderCell: (params) => (
        <ActionMenu
          tableType="transactions"
          onDetailsClick={() => handlePaymentDetailsClick(params)}
          onViewCustomerClick={() => handleViewCustomerClick(params)}
          onCopyIdClick={() =>
            navigator.clipboard
              .writeText(params?.row?.transactionId)
              .then(() => {
                showMessage('Transaction ID copied to clipboard', 'success');
              })
              .catch(() => {
                showMessage('Failed to copy transaction ID', 'error');
              })
          }
        />
      ),
      width: 20,
      align: 'center',
    },
    {
      field: 'gatewayReference',
      headerName: 'Transaction Reference',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
    },
    {
      field: 'subscriptionGatewayReference',
      headerName: 'Subscription Reference',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
    },
    {
      field: 'transactionID',
      headerName: 'Transaction ID(s)',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (_, row) => row?.transactionId,
    },
    {
      field: 'arn',
      headerName: 'ARN',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (_, row) => row?.acquirerReferenceNumber,
    },
    {
      field: 'orderReference',
      headerName: 'Order Reference',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (_, row) => row?.orderNumber,
    },
    {
      field: 'customerKey',
      headerName: 'Customer Key',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (_, row) => row?.customer?.memberKey,
    },
    {
      field: 'customer',
      headerName: 'Customer',
      filterable: true,
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      filterOperators: nameFilterOperators,
      valueGetter: (_, row) => `${row?.customer?.firstName} ${row?.customer?.lastName}`,
    },
  ];
  return columns;
};
export default TransactionColumns;
