import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { Box, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { GridCellParams, GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro';

const AmountInput = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;

  const [comparison, setComparison] = useState(item.value?.comparison || 'greaterThan');
  const [amount, setAmount] = useState(item.value?.amount || '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedApplyValue = useCallback(
    debounce((newItemValue) => {
      applyValue(newItemValue);
    }, 2000),
    [applyValue],
  );

  const handleComparisonChange = (event: SelectChangeEvent) => {
    const newComparison = event.target.value as 'greaterThan' | 'lessThan';
    setComparison(newComparison);
    debouncedApplyValue({ ...item, value: { ...item.value, comparison: newComparison, amount } });
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newAmount = event.target.value;
    setAmount(newAmount);
    debouncedApplyValue({ ...item, value: { ...item.value, comparison, amount: newAmount } });
  };

  useEffect(() => {
    setComparison(item.value?.comparison || 'greaterThan');
    setAmount(item.value?.amount || '');
  }, [item]);

  return (
    <Box sx={{ display: 'flex', gap: 1, mx: 1, alignItems: 'baseline', width: 220 }}>
      <FormControl variant="standard" sx={{ minWidth: 120 }}>
        <Select value={comparison} onChange={handleComparisonChange} label="Comparison">
          <MenuItem value="greaterThan">Greater Than</MenuItem>
          <MenuItem value="lessThan">Less Than</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Amount"
        value={amount}
        onChange={handleAmountChange}
        variant="standard"
        InputLabelProps={{ shrink: true }}
      />
    </Box>
  );
};

export const amountFilterOperators: GridFilterOperator[] = [
  {
    label: 'Amount Comparison',
    value: 'amountComparison',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (filterItem.value?.amount === undefined || filterItem.value?.comparison === undefined) {
        return null;
      }

      const { comparison, amount } = filterItem.value;
      return (params: GridCellParams) => {
        const value = params.value as number;
        if (comparison === 'greaterThan') {
          return value > amount;
        } else if (comparison === 'lessThan') {
          return value < amount;
        }
        return false;
      };
    },
    InputComponent: AmountInput,
  },
];
