import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { ApplicationContext } from '@one/api-models/lib/Admin/Common/ApplicationContext';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { CompleteAddPaymentMethodRequest } from '@one/api-models/lib/Sales/Payment/PaymentMethod/CompleteAddPaymentMethodRequest';
import { CompleteAddPaymentMethodResponse } from '@one/api-models/lib/Sales/Payment/PaymentMethod/CompleteAddPaymentMethodResponse';
import { InitAddPaymentMethodRequest } from '@one/api-models/lib/Sales/Payment/PaymentMethod/InitAddPaymentMethodRequest';
import { InitAddPaymentMethodResponse } from '@one/api-models/lib/Sales/Payment/PaymentMethod/InitAddPaymentMethodResponse';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { PAYMENT_AVS_MISMATCH_ERROR } from 'components/views/paymentPlans/constants/CybersourcePayment';
import { setPartners, setPermissions } from 'slices/applicationDataSlice';
import { setCreatePaymentMethodError, setIsLoadingCreatePaymentMethod } from 'slices/paymentDataSlice';

export const useCybersourceCreatePayment = () => {
  const dispatch = useDispatch();
  const { api } = useApiHelpers();
  const { apiErrorHandler, addApiError, showMessage } = useToastMessage();

  const applicationContextMutation = useMutation<ApplicationContext, ApiError>(
    'appContext',
    () => {
      return api.admin.contextLoad({});
    },
    {
      onSuccess: (value: ApplicationContext) => {
        const partners = [...value.partners];
        dispatch(setPartners(partners));

        const permissionsList = value?.roles.map((r) => r.permissions).flat() || [];
        dispatch(setPermissions(permissionsList));
      },
      onError: (error) => addApiError(error),
    },
  );

  const refetchApplicationContext = () => {
    dispatch(setCreatePaymentMethodError(undefined));
    applicationContextMutation.mutateAsync();
  };

  // Create Payment Method
  const initCreatePaymentMethodMutation = useMutation<
    InitAddPaymentMethodResponse,
    ApiError,
    {
      memberKey: string;
      paymentMethodToken: string;
      paymentMethodExpiration: Date;
      paymentGatewayIdentifier: string;
      billingDetails: BillingDetails;
    },
    unknown
  >(
    async ({ memberKey, paymentMethodToken, paymentMethodExpiration, paymentGatewayIdentifier, billingDetails }) => {
      return await api.payment.initCreatePaymentMethod({
        customerKey: memberKey,
        paymentMethodToken,
        paymentMethodExpiration,
        paymentGatewayIdentifier,
        billingDetails,
      } as InitAddPaymentMethodRequest);
    },
    {
      onError: (error) => apiErrorHandler(error, undefined, undefined, PAYMENT_AVS_MISMATCH_ERROR),
    },
  );

  const completeCreatePaymentMethodMutation = useMutation<
    CompleteAddPaymentMethodResponse,
    ApiError,
    {
      memberKey: string;
      paymentMethodReference?: string;
      isPreferredPaymentMethod: boolean;
      paymentGatewayIdentifier: string;
      billingDetails: BillingDetails;
      callback: (paymentMethod: PaymentMethod) => void;
      setAddPaymentModalOpen: (value: any) => void;
    },
    unknown
  >(
    async ({
      memberKey,
      paymentMethodReference,
      isPreferredPaymentMethod,
      paymentGatewayIdentifier,
      billingDetails,
      callback,
      setAddPaymentModalOpen,
    }) => {
      const response = await api.payment.completeCreatePaymentMethod({
        customerKey: memberKey,
        paymentMethodReference,
        isPreferredPaymentMethod,
        paymentGatewayIdentifier,
        billingDetails,
      } as CompleteAddPaymentMethodRequest);
      callback(response.paymentMethod);
      setAddPaymentModalOpen(false);
      return response;
    },
    {
      onSuccess: () => {
        showMessage('Payment method added successfully', 'success');
      },
      onError: apiErrorHandler,
    },
  );

  const performCreatePaymentMethod = async (
    token: string,
    memberKey: string | undefined,
    paymentMethodExpiration: Date,
    isDefaultPaymentMethod: boolean,
    paymentGatewayIdentifier: string,
    billingDetails: BillingDetails,
    callback: (paymentMethod: PaymentMethod) => void,
    setAddPaymentModalOpen: (value: any) => void,
  ) => {
    if (!memberKey) throw new Error('Failed to get memberKey');

    dispatch(setCreatePaymentMethodError(undefined));

    const order = await initCreatePaymentMethodMutation.mutateAsync({
      memberKey,
      paymentMethodToken: token,
      paymentMethodExpiration,
      paymentGatewayIdentifier,
      billingDetails,
    });

    performCompleteCreatePaymentMethod(
      memberKey,
      isDefaultPaymentMethod,
      paymentGatewayIdentifier,
      billingDetails,
      callback,
      setAddPaymentModalOpen,
      order.setupIntent.paymentMethodReference,
    );
  };

  const performCompleteCreatePaymentMethod = (
    memberKey: string,
    isPreferredPaymentMethod: boolean,
    paymentGatewayIdentifier: string,
    billingDetails: BillingDetails,
    callback: (paymentMethod: PaymentMethod) => void,
    setAddPaymentModalOpen: (value: any) => void,
    paymentMethodReference?: string,
  ) => {
    completeCreatePaymentMethodMutation.mutate({
      memberKey,
      paymentMethodReference,
      isPreferredPaymentMethod,
      paymentGatewayIdentifier,
      billingDetails,
      callback,
      setAddPaymentModalOpen,
    });
  };

  const resetCreatePaymentMethodError = () => {
    dispatch(setCreatePaymentMethodError(undefined));
  };

  useEffect(() => {
    dispatch(
      setIsLoadingCreatePaymentMethod(
        initCreatePaymentMethodMutation.isLoading ||
          completeCreatePaymentMethodMutation.isLoading ||
          applicationContextMutation.isLoading,
      ),
    );
  }, [
    dispatch,
    initCreatePaymentMethodMutation.isLoading,
    completeCreatePaymentMethodMutation.isLoading,
    applicationContextMutation.isLoading,
  ]);

  return {
    refetchApplicationContext,
    performCreatePaymentMethod,
    resetCreatePaymentMethodError,
  };
};
