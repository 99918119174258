import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Certificate } from '@one/api-models/lib/Membership/Account/Certificate';
import { Money } from '@one/api-models/lib/Money';

import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { AssetStatusLabel } from 'models/AssetStatusLabel';
import { Brand } from 'models/Brand';
import {
  CertificateAccommodationProductTypes,
  CertificateCruiseProductTypes,
  CertificateExperienceProductTypes,
} from 'models/customers/CertificateProductTypes';
import { selectActiveBrand } from 'slices/applicationDataSlice';

import { getAssetOriginName } from './helpers/walletHelpers';
import { CertificateTypeIcon } from './CertificateTypeIcon';

const useStyles = makeStyles(() => ({
  label: { display: 'inline-block', marginRight: '10px' },
  value: { display: 'inline-block' },
  copyButton: {
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '16px',
    fontWeight: 'bold',
    lineHeight: '1.4rem',
    borderColor: '#ccc',
  },
  headerIcon: {
    display: 'inline-block',
    width: '100%',
    maxHeight: '22px',
    maxWidth: '16px',
    justifyContent: 'center',
    opacity: 0.6,
    marginRight: '8px',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  testId: string;
  certificate?: Certificate;
}

export const CertificateDetailsDialog: React.FC<Props> = ({ open, onClose, certificate, testId }: Props) => {
  const { formatDate: _formatDate, formatCurrency: formatCurrencyFunc } = useFormat();
  const classes = useStyles() as any;
  const { hasPermission } = useAppHelpers();
  const activeBrand = useSelector(selectActiveBrand);

  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const formatDate = (date: string | Date | null | undefined): string => {
    if (!date) return 'N/A';
    return _formatDate(new Date(date!), true, 'dd MMM yyyy') ?? 'N/A';
  };

  const formatMoney = (money?: Money): string | null => formatCurrency(money?.amount, money?.currency);

  return certificate ? (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', py: 2.5 }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
            Certificate:
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {certificate.productTypes &&
              certificate?.productTypes?.length !== 0 &&
              certificate.productTypes?.map((productType) => (
                <Tooltip title={certificate.productTypes?.join(', ') || ''}>
                  <Box className={classes.headerIcon}>
                    <CertificateTypeIcon type={productType} iconFill="#FFFFFF" />
                  </Box>
                </Tooltip>
              ))}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">{certificate.name}</Typography>
              {certificate?.assetOrigin && (
                <Typography variant="body1" sx={{ color: 'white' }}>
                  {`${
                    activeBrand?.key === Brand.FutureCruisePackagesUk ? 'Collection: ' : 'Package: '
                  }${getAssetOriginName(certificate)}`}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="small"
          disableRipple
          data-testid={`${testId}CloseButton`}
          sx={{ alignSelf: 'flex-start' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6} container>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle1" className={classes.label}>
                  Serial Number
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4.5} textAlign={'end'}>
                <Typography variant="body1" className={classes.value}>
                  {certificate.serialNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6}>
              <Grid item xs={6} sm={3}>
                <Typography variant="subtitle1" className={classes.label}>
                  Status
                </Typography>
              </Grid>
              <Grid item xs={6} sm={7} textAlign={'end'}>
                <Typography variant="body1" className={classes.value}>
                  {AssetStatusLabel[certificate.status]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6} container spacing={2}>
            <Grid item xs={12} container gap={1}>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle1" className={classes.label}>
                  Issued On
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} textAlign={'end'}>
                <Typography variant="body1" className={classes.value}>
                  {formatDate(certificate.issuedOn)}
                </Typography>
              </Grid>
            </Grid>
            {certificate?.expiresOn && (
              <Grid item xs={12} container gap={1}>
                <Grid item xs={6} sm={6}>
                  <Typography variant="subtitle1" className={classes.label}>
                    {certificate.productTypes?.some(
                      (type) =>
                        Object.values(CertificateAccommodationProductTypes).includes(type) ||
                        Object.values(CertificateCruiseProductTypes).includes(type) ||
                        Object.values(CertificateExperienceProductTypes).includes(type),
                    )
                      ? 'Book by date'
                      : 'Expiry Date'}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} textAlign={'end'}>
                  <Typography variant="body1" className={classes.value}>
                    {formatDate(certificate.expiresOn)}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {certificate.productTypes?.some((type) => Object.values(CertificateCruiseProductTypes).includes(type)) &&
              certificate.restrictions?.SailBy && (
                <Grid item xs={12} container gap={1}>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="subtitle1" className={classes.label}>
                      Sail by date
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} textAlign={'end'}>
                    <Typography variant="body1" className={classes.value}>
                      {formatDate(certificate.restrictions.SailBy)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            {certificate.productTypes?.some((type) =>
              Object.values(CertificateAccommodationProductTypes).includes(type),
            ) &&
              certificate.restrictions?.TravelBy && (
                <Grid item xs={12} container gap={1}>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="subtitle1" className={classes.label}>
                      Travel by date
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} textAlign={'end'}>
                    <Typography variant="body1" className={classes.value}>
                      {formatDate(certificate.restrictions.TravelBy)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            {certificate.productTypes?.some((type) =>
              Object.values(CertificateExperienceProductTypes).includes(type),
            ) &&
              certificate.restrictions?.UseBy && (
                <Grid item xs={12} container gap={1}>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="subtitle1" className={classes.label}>
                      Use by date
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} textAlign={'end'}>
                    <Typography variant="body1" className={classes.value}>
                      {formatDate(certificate.restrictions.UseBy)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </Grid>

          {certificate.redeemedOn != null && (
            <Grid item xs={12} sm={6} container gap={1} pl={1}>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle1" className={classes.label}>
                  Redeemed On
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="body1" className={classes.value}>
                  {formatDate(certificate.redeemedOn)}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6} container spacing={2}>
            <Grid item xs={12} container gap={1}>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle1" className={classes.label}>
                  Internal Cost
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} textAlign={'end'}>
                <Typography variant="body1" className={classes.value}>
                  {formatMoney(certificate.internalCost)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container gap={1}>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle1" className={classes.label}>
                  Allocated Price
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} textAlign={'end'}>
                <Typography variant="body1" className={classes.value}>
                  {formatMoney(certificate.allocatedPrice)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container gap={1}>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle1" className={classes.label}>
                  Fair Market Value
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} textAlign={'end'}>
                <Typography variant="body1" className={classes.value}>
                  {formatMoney(certificate.fairMarketValue)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {hasPermission(['Customers.Service.Asset:Debug']) && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item container xs={12} gap={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className={classes.label}>
                    Business Model Type
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} textAlign={'end'}>
                  <Typography variant="body1" className={classes.value}>
                    {certificate.businessModelType}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} gap={2}>
                <Grid item xs={6} sm={6}>
                  <Typography variant="subtitle1" className={classes.label}>
                    Fee types covered
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} textAlign={'end'}>
                  <Typography variant="body1" className={classes.value}>
                    {certificate.feeTypesCovered}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item container rowSpacing={1} xs={12} mb={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Condition Rules</Typography>
                </Grid>
                <Grid item xs={12}>
                  <textarea rows={7} style={{ border: 0, width: '100%' }}>
                    {certificate.conditionRules}
                  </textarea>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>

      {hasPermission(['Customers.Service.Asset:Debug']) && (
        <DialogActions>
          {!!certificate.conditionRules && certificate.conditionRules !== '[]' && (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(certificate.conditionRules || '');
              }}
              disabled={certificate.conditionRules == null ? true : false}
              data-testid={`${testId}CopyJSONButton`}
            >
              Copy JSON
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  ) : null;
};
