import { Box, Typography } from '@mui/material';
import { Order } from '@one/api-models/lib/Admin/Sales/Order';
import { DepositPlanOption } from '@one/api-models/lib/Admin/Sales/PaymentPlan/DepositPlanOption';
import { ReservationOption } from '@one/api-models/lib/Admin/Sales/PaymentPlan/ReservationOption';
import { OrderFulfillmentStatus } from '@one/api-models/lib/OrderFulfillmentStatus';
import { OrderStatus } from '@one/api-models/lib/OrderStatus';

import { useFormat } from 'components/hooks/useFormat';

import { Section } from './Section';

interface OrderItemsProps {
  order?: Order;
}

export const OrderReservationPaymentSection = ({ order }: OrderItemsProps) => {
  const { formatCurrency: formatCurrencyFunc, formatDate } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const reservationOptionPlan = order?.paymentPlanOptions?.find((plan) => plan.$type === ReservationOption.$type) as
    | ReservationOption
    | undefined;
  const depositOptionPlan = order?.paymentPlanOptions?.find((plan) => plan.$type === DepositPlanOption.$type) as
    | DepositPlanOption
    | undefined;

  if (
    !reservationOptionPlan ||
    order?.orderFulfillmentStatus !== OrderFulfillmentStatus.Reserved ||
    order?.orderStatus === OrderStatus.OrderCancelled
  ) {
    return null;
  }

  const downPaymentDueDate = reservationOptionPlan
    ? formatDate(reservationOptionPlan.downPaymentDueDate, true, 'PP')
    : undefined;

  // payment plan exists only if deposit amount was already paid
  const paymentPlan = order?.paymentPlan;

  return (
    <Section title="Payment Options" showDivider={true}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pl: 1,
        }}
        component="ul"
        data-testid="order-reservation-options"
        gap={1}
      >
        <Typography variant="body1" component="li">
          {`Full payment ${
            order?.orderTotalPrice
              ? formatCurrency(order?.orderTotalPrice.amount, order?.orderTotalPrice.currency)
              : null
          } due by ${downPaymentDueDate}`}
        </Typography>
        {depositOptionPlan && !paymentPlan ? (
          <Typography variant="body1" component="li">
            {`Deposit ${formatCurrency(
              depositOptionPlan.minDownPaymentAmount.amount,
              depositOptionPlan.minDownPaymentAmount.currency,
            )} due by ${downPaymentDueDate}; remaining balance of ${formatCurrency(
              depositOptionPlan.balance.amount,
              depositOptionPlan.balance.currency,
            )} will be due by ${formatDate(depositOptionPlan.balanceDueDate, true, 'PP')}`}
          </Typography>
        ) : null}
      </Box>
    </Section>
  );
};
