import { BookingQuestionType } from '@one/api-models/lib/Sales/Travel/Experience/BookingQuestionType';

interface StructuredBookingQuestions {
  type: BookingQuestionType;
  customLabel?: string;
}

export const travelerBookingQuestions: Record<string, StructuredBookingQuestions[]> = {
  'primary-traveler': [
    {
      type: BookingQuestionType.FullNameFirst,
      customLabel: 'First name',
    },
    {
      type: BookingQuestionType.FullNameLast,
      customLabel: 'Last name',
    },
  ],
  'personal-info': [
    { type: BookingQuestionType.FullNameFirst, customLabel: 'First name' },
    { type: BookingQuestionType.FullNameLast, customLabel: 'Last name' },
    { type: BookingQuestionType.DateOfBirth, customLabel: 'Date of birth' },
    { type: BookingQuestionType.PassportNumber, customLabel: 'Passport number' },
    { type: BookingQuestionType.PassportNationality, customLabel: 'Passport nationality' },
    { type: BookingQuestionType.PassportExpiry, customLabel: 'Passport expiry date' },
    { type: BookingQuestionType.Height, customLabel: 'Height' },
    { type: BookingQuestionType.Weight, customLabel: 'Weight' },
  ],
};
