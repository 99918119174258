import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CurrencyEuroIcon from '@mui/icons-material/Euro';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';

export const mapCurrency = (currency?: string) => {
  switch (currency) {
    case 'USD':
      return <AttachMoneyIcon sx={{ fontSize: '1rem' }} />;
    case 'GBP':
      return <CurrencyPoundIcon sx={{ fontSize: '1rem' }} />;
    case 'EUR':
      return <CurrencyEuroIcon sx={{ fontSize: '1rem' }} />;
    default:
      return undefined;
  }
};

export const isBillingDetailsRequiredByPaymentProviderValid = (billingDetails?: BillingDetails) => {
  return (
    !billingDetails ||
    (!!billingDetails &&
      (!billingDetails?.billingAddress?.countryCode ||
        !billingDetails?.billingAddress?.postalCode ||
        (['US', 'CA'].includes(billingDetails?.billingAddress?.countryCode ?? '') &&
          !billingDetails?.billingAddress?.stateCode) ||
        !billingDetails?.firstName ||
        !billingDetails?.lastName ||
        !billingDetails?.email))
  );
};
