import { useDispatch } from 'react-redux';

import { GridFilterModel } from '@mui/x-data-grid-pro';
import { OrderFulfillmentStatus } from '@one/api-models/lib/OrderFulfillmentStatus';

import { Filters, OrderDataGrid } from 'components/views/sales/components/OrderDataGrid';
import {
  selectAllBrandsEnabled,
  selectFiltersModel,
  setAllBrandsEnabled,
  setFiltersModel,
} from 'slices/salesReservationListDataSlice';

const defaultOrderFilters: Filters = {
  orderStatuses: [],
  orderFulfillmentStatuses: [OrderFulfillmentStatus.Reserved],
  orderPaymentStatuses: [],
};

export const Reservations = () => {
  const dispatch = useDispatch();

  return (
    <OrderDataGrid
      title="Reservations"
      testId="Reservations"
      label="Show reservations for all brands"
      selectAllBrandsEnabled={selectAllBrandsEnabled}
      selectFiltersModel={selectFiltersModel}
      setAllBrandsEnabled={(enableAllBrands: boolean) => dispatch(setAllBrandsEnabled(enableAllBrands))}
      setFiltersModel={(filters: GridFilterModel) => dispatch(setFiltersModel(filters))}
      hideFulfillmentStatusFilter
      defaultOrderFilters={defaultOrderFilters}
    />
  );
};
