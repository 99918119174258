import { useEffect, useState } from 'react';
import { isValid, parse } from 'date-fns';

import { Box, TextField } from '@mui/material';
import { GridCellParams, GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro';

const DateInput = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;

  const [startDate, setStartDate] = useState(item.value?.start || '');
  const [endDate, setEndDate] = useState(item.value?.end || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'start' | 'end') => {
    const newValue = { ...item.value, [type]: event.target.value };

    if (type === 'start') {
      setStartDate(event.target.value);
    } else {
      setEndDate(event.target.value);
    }

    applyValue({ ...item, value: newValue });
  };

  useEffect(() => {
    setStartDate(item.value?.start || '');
    setEndDate(item.value?.end || '');
  }, [item]);

  return (
    <Box sx={{ display: 'flex', gap: 1, mx: 1, width: 220 }}>
      <TextField
        type="date"
        label="Start Date"
        value={startDate}
        onChange={(e: any) => handleChange(e, 'start')}
        variant="standard"
        InputLabelProps={{ shrink: true }}
        inputProps={{
          max: endDate || undefined,
        }}
      />
      <TextField
        type="date"
        label="End Date"
        value={endDate}
        onChange={(e: any) => handleChange(e, 'end')}
        variant="standard"
        InputLabelProps={{ shrink: true }}
        inputProps={{
          min: startDate || undefined,
        }}
      />
    </Box>
  );
};

export const dateRangeFilterOperators: GridFilterOperator[] = [
  {
    label: 'Between',
    value: 'dateBetween',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value?.start || !filterItem.value?.end) {
        return null;
      }
      return (params: GridCellParams) => {
        const date = parse(params.value as string, 'yyyy-MM-dd', new Date());
        const startDate = parse(filterItem.value.start, 'yyyy-MM-dd', new Date());
        const endDate = parse(filterItem.value.end, 'yyyy-MM-dd', new Date());
        return isValid(date) && isValid(startDate) && isValid(endDate) && date >= startDate && date <= endDate;
      };
    },
    InputComponent: DateInput,
  },
];
