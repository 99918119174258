import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Box, FormControlLabel, Switch } from '@mui/material';
import { GridFilterModel, GridPaginationModel, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { TransactionListResponse } from '@one/api-models/lib/Admin/Transaction/TransactionListResponse';

import { ApiError } from 'apiAccess/api-client';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { PageHeader } from 'components/_common/PageHeader';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import { selectAllBrandsEnabled, setAllBrandsEnabled } from 'slices/transactionsDataSlice';

import { TransactionsDataGrid } from './components/TransactionsDataGrid';

export const Transactions = () => {
  const defaultPageSize = 25;
  const dispatch = useDispatch();
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();
  const { getEndDate, getStartDate } = useFormat();

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const allBrandsEnabled = useSelector(selectAllBrandsEnabled);

  const [showSkeleton, setShowSkeleton] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [shouldResetPage, setShouldResetPage] = useState(false);

  //Filters
  const [filters, setFilters] = useState({
    statusFilter: [] as number[],
    customerEmailFilter: undefined as string | undefined,
    transactionGatewayReferenceFilter: undefined as string | undefined,
    subscriptionGatewayReferenceFilter: undefined as string | undefined,
    arnFilter: undefined as string | undefined,
    orderReferenceFilter: undefined as string | undefined,
    transactionIdsFilter: [] as number[],
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
    customerKey: undefined as string | undefined,
    customerFirstName: undefined as string | undefined,
    customerLastName: undefined as string | undefined,
  });
  const [prevFilters, setPrevFilters] = useState<GridFilterModel>({ items: [] });

  const clearFilters = () => {
    setFilters({
      statusFilter: [] as number[],
      customerEmailFilter: undefined,
      transactionGatewayReferenceFilter: undefined,
      subscriptionGatewayReferenceFilter: undefined,
      arnFilter: undefined,
      orderReferenceFilter: undefined,
      transactionIdsFilter: [] as number[],
      startDate: undefined,
      endDate: undefined,
      customerKey: undefined,
      customerFirstName: undefined,
      customerLastName: undefined,
    });
  };

  useEffect(() => {
    setShowSkeleton(true);
  }, [activePartner, activeBrand]);

  const {
    data: transactions,
    isFetching,
    status,
  } = useQuery<TransactionListResponse, ApiError>(
    ['transactionsData', activeBrand, activePartner, page, pageSize, filters, allBrandsEnabled],
    () =>
      api.transaction.loadTransactionList({
        ...getFilterPayload(),
        listingCriteria: {
          skip: page * pageSize,
          take: pageSize,
        },
      }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
      onError: (error: ApiError) => addApiError(error),
      onSuccess: () => {
        setShowSkeleton(false);
        setShouldResetPage(false);
      },
    },
  );

  const handlePaginationChanges = (data: GridPaginationModel) => {
    setPageSize(data.pageSize);
    setPage(data.page);
  };

  const resetPage = () => {
    setPage(0);
    setPageSize(defaultPageSize);
    setShouldResetPage(true);
  };

  useEffect(() => {
    // onSuccess from useQuery is not being called at the initial render
    if (status === 'success') {
      setShowSkeleton(false);
    }
  }, [status]);

  const handleFilterModelChange = (filters: GridFilterModel) => {
    // Handle empty filters array
    if (!filters?.items?.length) {
      clearFilters();
      setPrevFilters(filters);
      resetPage();
    }

    const { field: filterType, value: filterValue } = filters?.items[0];
    setFilters((prevFilters) => {
      if (filterValue !== undefined) resetPage();
      switch (filterType) {
        case 'Status':
          return {
            ...prevFilters,
            statusFilter: filterValue !== undefined ? [filterValue] : [],
          };
        case 'customerEmail':
          return {
            ...prevFilters,
            customerEmailFilter: filterValue && (filterValue as string),
          };
        case 'gatewayReference':
          return {
            ...prevFilters,
            transactionGatewayReferenceFilter: filterValue && (filterValue as string),
          };
        case 'subscriptionGatewayReference':
          return {
            ...prevFilters,
            subscriptionGatewayReferenceFilter: filterValue && (filterValue as string),
          };
        case 'arn':
          return {
            ...prevFilters,
            arnFilter: filterValue && (filterValue as string),
          };
        case 'orderReference':
          return {
            ...prevFilters,
            orderReferenceFilter: filterValue && (filterValue as string),
          };
        case 'transactionID':
          const transactionIdsArray =
            filterValue
              ?.split(' ')
              .map((id: string) => parseInt(id, 10))
              .filter((id: number) => !isNaN(id)) || [];
          return {
            ...prevFilters,
            transactionIdsFilter: transactionIdsArray,
          };
        case 'createdDateTime':
          return {
            ...prevFilters,
            startDate: filterValue?.start,
            endDate: filterValue?.end,
          };
        case 'customerKey':
          return {
            ...prevFilters,
            customerKey: filterValue && (filterValue as string),
          };
        case 'customer':
          return {
            ...prevFilters,
            customerFirstName: filterValue?.firstName && (filterValue.firstName as string),
            customerLastName: filterValue?.lastName && (filterValue.lastName as string),
          };
        default:
          return prevFilters;
      }
    });

    setPrevFilters(filters);

    if (prevFilters?.items?.length > 0) {
      if (filters?.items[0]?.field !== prevFilters?.items[0]?.field) {
        filters.items[0].value = undefined;
        clearFilters();
      }
    }
  };

  const getFilterPayload = () => {
    const defaultStartDate = getStartDate(new Date('2010-01-01'));

    const payload: any = {
      partnerKeys: allBrandsEnabled ? [] : [`${activePartner?.key}`],
      brandKeys: allBrandsEnabled ? [] : [`${activeBrand?.key}`],
      createdStartDate: filters.startDate ? getStartDate(new Date(filters.startDate)) : defaultStartDate,
      createdEndDate: filters?.endDate ? getEndDate(new Date(filters?.endDate)) : undefined,
      statuses: filters.statusFilter || [],
      transactionIds: filters.transactionIdsFilter || [],
    };

    if (filters.customerEmailFilter) {
      payload['customerEmail'] = filters.customerEmailFilter;
    }
    if (filters.transactionGatewayReferenceFilter) {
      payload['transactionGatewayReference'] = filters.transactionGatewayReferenceFilter;
    }
    if (filters.subscriptionGatewayReferenceFilter) {
      payload['subscriptionGatewayReference'] = filters.subscriptionGatewayReferenceFilter;
    }
    if (filters.arnFilter) {
      payload['acquirerReferenceNumber'] = filters.arnFilter;
    }
    if (filters.orderReferenceFilter) {
      payload['orderNumber'] = filters.orderReferenceFilter;
    }
    if (filters.customerKey) {
      payload['customerKey'] = filters.customerKey;
    }
    if (filters.customerFirstName || filters.customerLastName) {
      payload['customerFirstName'] = filters.customerFirstName;
      payload['customerLastName'] = filters.customerLastName;
    }

    return payload;
  };
  return (
    <Box>
      <PageHeader title="Transactions" testId={'Transactions'} />
      <LoadingScreen open={showSkeleton} message={'Loading...'} />
      <TransactionsDataGrid
        isLoading={isFetching}
        transactions={transactions?.transactions}
        allBrandsEnabled={allBrandsEnabled}
        shouldResetPage={shouldResetPage}
        handlePaginationChanges={handlePaginationChanges}
        handleFilterModelChange={handleFilterModelChange}
        page={page}
        pageSize={pageSize}
        gridToolbar={
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <GridToolbarFilterButton />
              <FormControlLabel
                control={
                  <Switch
                    checked={allBrandsEnabled}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setAllBrandsEnabled(event.target.checked));
                      setShouldResetPage(true);
                    }}
                    inputProps={{
                      // eslint-disable-next-line
                      //@ts-ignore
                      'data-testid': `TransactionsAllBrandsSwitchInput`,
                    }}
                  />
                }
                label="Show transactions for all brands"
              />
            </Box>
            <GridToolbarExport
              csvOptions={{
                fileName: `Transactions_${new Date().toISOString()}`,
                allColumns: true,
              }}
            />
          </Box>
        }
      />
    </Box>
  );
};
