export enum Brand {
  Carnival = 'CCL',
  UVCExperiences = 'UVX',
  ONE = 'OMC',
  MedallionClassMarket = 'MCM',
  GuestBookings = 'GBK',
  GuestBookingsUK = 'GBU',
  CapitalVacationsClub = 'CVC',
  FutureCruisePackages = 'FCP',
  FutureCruisePackagesCanada = 'CAN',
  FutureCruisePackagesUk = 'PCU',
  ClubPrincess = 'CPR',
  Victra = 'VIC',
  BassPro = 'BPR',
  SanDiego = 'SDG',
  Prestigate = 'PRN',
  PrincessPartnerRewards = 'PPR',
  Vivri = 'VIV',
  MagicBreak = 'MBO',
  HolidayInnClub = 'HIC',
  ClubVacay = 'CVY',
  GuestBookingsAMZ = 'AMZ',
  MemberGetaways = 'MGW',
  HotelsByCruisecom = 'HBC',
  PrincessCUG = 'CUG',
  ElCidExperiences = 'ELX',
  TravelGuestBookings = 'TGB',
  EmployeeBenefits = 'OEB',
  ExploreByPuebloBonito = 'EPB',
  ExploreByPuebloBonitoMexico = 'PBM',
  SupplierTesting = 'STG',
  UExperiencesMexico = 'UXM',
  UExperiencesCanada = 'UXC',
  LeisureRewards = 'LER',
  Aspirex = 'ASP',
  CruiseComPlus = 'CRP',
  Bookit = 'BKI',
  BookitCoins = 'BKC',
}
