import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { Box, Button, Divider, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { OnBehalfOfMemberRequest } from '@one/api-models/lib/Admin/Members/Request/OnBehalfOfMemberRequest';
import { OnBehalfOfMemberResponse } from '@one/api-models/lib/Admin/Members/Response/OnBehalfOfMemberResponse';
import { DashboardResponse } from '@one/api-models/lib/Agency/Dashboard/AgencyAdvisor/DashboardResponse';
import { AgentDetails } from '@one/api-models/lib/Agency/Dashboard/AgentDetails';
import { Transaction } from '@one/api-models/lib/Agency/Dashboard/Transaction';

import { ApiError } from 'apiAccess/api-client';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { PageHeader } from 'components/_common/PageHeader';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand } from 'slices/applicationDataSlice';

const pageSize = 25;

export const AgentDashboard = () => {
  const { api } = useApiHelpers();
  const { apiErrorHandler, showMessage } = useToastMessage();
  const { formatNumber } = useFormat();
  const [agent, setAgent] = useState<AgentDetails | undefined>(undefined);
  const isLoading = useIsMutating({ mutationKey: 'agentDashboard' }) > 0;
  const isOBOLoading = useIsMutating({ mutationKey: 'agentOBO' }) > 0;
  const activeBrand = useSelector(selectActiveBrand);
  const testIdPrefix = 'AgentDashboard';

  useEffect(() => {
    agentMutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agentMutation = useMutation<DashboardResponse, ApiError>(() => api.agent.dashboard(), {
    mutationKey: 'agentDashboard',
    onSuccess: (value: DashboardResponse) => {
      setAgent(value.agentDetails as AgentDetails);
    },
    onError: apiErrorHandler,
  });

  const onBehalfOfMutation = useMutation<OnBehalfOfMemberResponse, ApiError, OnBehalfOfMemberRequest, unknown>(
    (request) => {
      return api.members.onBehalfOf(request);
    },
    {
      mutationKey: 'agentOBO',
      onError: apiErrorHandler,
    },
  );

  const handleRedeemClick = async () => {
    try {
      const response = await onBehalfOfMutation.mutateAsync({ brandKey: activeBrand?.key ?? '', destinationUrl: '' });
      window.open(response.redirectUrl);
    } catch (e) {
      showMessage('Cannot start session on behalf of customer', 'error');
    }
  };

  const columns: GridColDef[] = [];

  return (
    <Box>
      <LoadingScreen open={isLoading || isOBOLoading} message={'Loading...'} />
      <PageHeader title={agent?.name?.trim() ? `Welcome, ${agent?.name}!` : 'Welcome!'} testId={testIdPrefix} />
      <Box width="fit-content">
        {agent?.alternativeCurrencies.map((altCurrency, index) => (
          <Box pt={3}>
            <Typography variant="subtitle2" sx={{ color: '#545969' }}>
              {altCurrency.name}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h3" sx={{ pr: 17 }}>
                {formatNumber(altCurrency.amount)}
              </Typography>
              {index === 0 && agent.capabilities?.canNavigateToOwnMemberAccount ? (
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleRedeemClick}
                  disabled={isOBOLoading}
                  data-testid={`${testIdPrefix}ShopButton`}
                >
                  Shop
                </Button>
              ) : null}
            </Box>
          </Box>
        ))}
        {(!agent?.alternativeCurrencies || agent?.alternativeCurrencies.length === 0) &&
          agent?.capabilities?.canNavigateToOwnMemberAccount && (
            <Box pt={3}>
              <Button
                variant="contained"
                size="small"
                onClick={handleRedeemClick}
                disabled={isOBOLoading}
                data-testid={`${testIdPrefix}ShopButton`}
              >
                Shop
              </Button>
            </Box>
          )}

        {agent?.estimatedAlternativeCurrency ? (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" sx={{ color: '#545969' }}>
              Estimated future points: (
              {`${formatNumber(agent?.estimatedAlternativeCurrency?.amount)} ${
                agent?.estimatedAlternativeCurrency?.name
              }`}
              )
            </Typography>
          </>
        ) : null}
        <Divider sx={{ my: 2 }} />
      </Box>
      <Typography variant="body1" sx={{ fontSize: '11px', fontWeight: '500', textTransform: 'uppercase' }}>
        Latest transactions
      </Typography>
      {agent?.transactions && agent?.transactions.length > 0 ? (
        <DataGrid
          rows={agent?.transactions.map((item: Transaction) => ({ ...item }))}
          columns={columns}
          hideFooter={agent?.transactions?.length <= pageSize}
        />
      ) : (
        <Typography variant="caption" pt={2}>
          Transaction history currently not available.
        </Typography>
      )}
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};
