import { useEffect, useRef, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, InputAdornment, useTheme } from '@mui/material';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { Money } from '@one/api-models/lib/Money';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';
import { PaymentProcessRequest } from '@one/api-models/lib/Sales/Payment/Transaction/PaymentProcessRequest';
import { PaymentProcessResponse } from '@one/api-models/lib/Sales/Payment/Transaction/PaymentProcessResponse';
import { PaymentType } from '@one/api-models/lib/Sales/Payment/Transaction/PaymentType';

import { ApiError } from 'apiAccess/api-client';
import ControlledTextField from 'common/ControlledTextField';
import { isBillingDetailsRequiredByPaymentProviderValid, mapCurrency } from 'common/payment/utils';
import { Loading } from 'components/_common/Loading';
import { PaymentProvider } from 'components/_common/PaymentProvider';
import { useRetrievePaymentMethodList } from 'components/hooks/paymentHooks';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { AddPaymentMethodComponentRef } from 'models/AddPaymentMethodComponentRef';
import { Customer } from 'models/customers/Customer';
import { PaymentProviderList } from 'models/PaymentProvider';
import { setCreatePaymentMethodError } from 'slices/paymentDataSlice';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SectionTitle,
  Typography,
} from 'styled';
import { formatAddress } from 'utils/address';

import { BillingDetailsComponentRef, BillingDetailsForm } from '../../_common/BillingDetailsForm';
import { CustomerSelectorAutocomplete } from '../customers/components/CustomerSelectorAutocomplete';

import { CardSelector } from './components/CardSelector';
import { PaymentMethodOption } from './components/EditPaymentPlanDialog';

interface TakePaymentDialogProps {
  orderNumber: string;
  paymentMethodId?: number;
  balance: Money;
  open: boolean;
  handleClose: (shouldRefetch: boolean) => void;
  testIdPrefix: string;
  defaultBillingDetails: BillingDetails;
  customer: Customer;
}
interface TakePaymentForm {
  amount: number;
  description?: string;
  notes?: string;
  paymentMethodReference?: string;
}

export const TakePaymentDialog = ({
  orderNumber,
  balance,
  open,
  handleClose,
  testIdPrefix,
  paymentMethodId,
  defaultBillingDetails,
  customer,
}: TakePaymentDialogProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const formRef = useRef<HTMLFormElement>(null);
  const billingDetailsFormRef = useRef<BillingDetailsComponentRef>(null);
  const addPaymentMethodFormRef = useRef<AddPaymentMethodComponentRef>(null);
  const minPaymentAmount = 1;
  const { formatCurrency: formatCurrencyFunc } = useFormat();
  const { api } = useApiHelpers();
  const { apiErrorHandler, addMessage } = useToastMessage();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };
  const [additionalPaymentMethodListOptions, setAdditionalPaymentMethodListOptions] = useState<PaymentMethodOption[]>(
    [],
  );
  const [selectedPaymentMethodReference, setSelectedPaymentMethodReference] = useState<PaymentMethodOption | undefined>(
    undefined,
  );
  const [addPaymentMethodOpen, setAddPaymentMethodOpen] = useState<boolean>(false);
  const [billingDetailsOpen, setBillingDetailsOpen] = useState<boolean>(false);
  const [billingDetails, setBillingDetails] = useState<BillingDetails>(defaultBillingDetails);

  const validationSchema: yup.SchemaOf<TakePaymentForm> = yup.object().shape({
    amount: yup
      .number()
      .typeError('Amount is required.')
      .required('Amount is required.')
      .min(minPaymentAmount, `Minimum amount is ${formatCurrency(minPaymentAmount, balance?.currency)}.`)
      .max(balance?.amount, `Maximum amount is ${formatCurrency(balance?.amount, balance?.currency)}.`),
    paymentMethodReference: yup.string().when('addPaymentMethodOpen', {
      is: true,
      then: yup.string().required('Payment method is required.'),
      otherwise: yup.string().notRequired(),
    }),
    description: yup.string().trim(),
    notes: yup.string().trim(),
  });

  const defaultValues: TakePaymentForm = {
    amount: 0,
    description: '',
    notes: '',
  };
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<TakePaymentForm>({ mode: 'onBlur', defaultValues, resolver: yupResolver(validationSchema) });

  const { isLoading: isProcessPaymentLoading, mutate: mutateProcessPayment } = useMutation<
    any,
    ApiError,
    PaymentProcessRequest,
    unknown
  >((request) => api.payment.processPayment(request), {
    mutationKey: 'processPaymentMutation',
    onSuccess: (value: PaymentProcessResponse) => {
      addMessage({
        label: 'Payment successfully processed.',
        severity: 'success',
      });
      handleClose(true);
    },
    onError: (error) => {
      apiErrorHandler(error);
    },
  });

  const { isFetching: isFetchingAdditionalPaymentMethodList, data: additionalPaymentMethodListData } =
    useRetrievePaymentMethodList(customer.memberKey, true);

  useEffect(() => {
    if (additionalPaymentMethodListData && additionalPaymentMethodListData?.paymentMethods?.length > 0) {
      const additionalFormattedPaymentMethods = additionalPaymentMethodListData?.paymentMethods?.map(
        (i: PaymentMethod) => ({
          code: i.paymentMethodId.toString(),
          label: `${mapGatewayIdentifierLabel(i.gatewayIdentifier)} \n **** **** **** ${i.description}`,
          isDefaultPaymentMethod: false,
          expirationMonth: i.expirationMonth,
          expirationYear: i.expirationYear,
          billingDetails: i.billingDetails,
        }),
      );
      setAddPaymentMethodOpen(false);
      setAdditionalPaymentMethodListOptions(additionalFormattedPaymentMethods);
      const defaultPaymentMethod = paymentMethodId
        ? additionalFormattedPaymentMethods.find((i: PaymentMethodOption) => Number(i.code) === paymentMethodId)
        : additionalFormattedPaymentMethods[0];

      if (defaultPaymentMethod) {
        setValue('paymentMethodReference', defaultPaymentMethod?.code);
        setSelectedPaymentMethodReference(defaultPaymentMethod);

        const currentBillingDetails = defaultPaymentMethod.billingDetails ?? defaultBillingDetails;
        setBillingDetails(currentBillingDetails);
        setBillingDetailsOpen(isBillingDetailsRequiredByPaymentProviderValid(currentBillingDetails));
      }
    } else {
      setAddPaymentMethodOpen(true);
    }
  }, [additionalPaymentMethodListData, paymentMethodId, defaultBillingDetails, setValue]);

  const mapGatewayIdentifierLabel = (gatewayIdentifier: string) => {
    const provider = PaymentProviderList.find((provider) => gatewayIdentifier.includes(provider));
    return provider ? provider : 'Unknown';
  };

  const handleChangePaymentMethod = (paymentMethodReference: string | undefined) => {
    const selectedPaymentMethod = additionalPaymentMethodListOptions.find(
      (i: PaymentMethodOption) => i.code === paymentMethodReference,
    );
    if (selectedPaymentMethod) {
      const currentBillingDetails = selectedPaymentMethod.billingDetails ?? defaultBillingDetails;
      setBillingDetails(currentBillingDetails);
      setBillingDetailsOpen(isBillingDetailsRequiredByPaymentProviderValid(currentBillingDetails));

      setSelectedPaymentMethodReference(selectedPaymentMethod);
    }
  };

  const handleProcessPayment = (data: TakePaymentForm, billingDetails?: BillingDetails) => {
    const request: PaymentProcessRequest = {
      amountToPay: {
        amount: data.amount,
        currency: balance?.currency,
        isEstimated: false,
      },
      memberKey: customer.memberKey,
      orderNumber: orderNumber,
      paymentMethodId: Number(data.paymentMethodReference),
      paymentType: PaymentType.Adhoc,
      description: data.description,
      notes: data.notes,
      billingInfo: {
        billingDetails: billingDetails,
      },
    };
    mutateProcessPayment(request);
  };

  const handleTakePayment = async (data: TakePaymentForm) => {
    let currentBillingDetails: BillingDetails | undefined = billingDetails;
    if (billingDetailsOpen) {
      currentBillingDetails = await billingDetailsFormRef.current?.getBillingDetails();
    }

    handleProcessPayment(data, currentBillingDetails);
  };

  const handleAddPaymentMethod = (paymentMethod: PaymentMethod) => {
    setValue('paymentMethodReference', paymentMethod.paymentMethodId.toString());
    setAdditionalPaymentMethodListOptions([
      ...additionalPaymentMethodListOptions,
      {
        code: paymentMethod.paymentMethodId.toString(),
        label: `${mapGatewayIdentifierLabel(paymentMethod.gatewayIdentifier)} \n **** **** **** ${
          paymentMethod.description
        }`,
        isDefaultPaymentMethod: false,
        expirationMonth: paymentMethod.expirationMonth,
        expirationYear: paymentMethod.expirationYear,
        billingDetails: paymentMethod.billingDetails,
      },
    ]);
    setAddPaymentMethodOpen(false);

    const currentBillingDetails = paymentMethod ? paymentMethod?.billingDetails : defaultBillingDetails;
    setBillingDetails(currentBillingDetails);
    setBillingDetailsOpen(isBillingDetailsRequiredByPaymentProviderValid(currentBillingDetails));
    handleProcessPayment(getValues(), currentBillingDetails);
  };

  const resetCreatePaymentMethodError = () => {
    dispatch(setCreatePaymentMethodError(undefined));
  };

  const handleSubmitTakePayment = async () => {
    if (addPaymentMethodFormRef.current && addPaymentMethodOpen) {
      const isAddPaymentMethodValid = await addPaymentMethodFormRef.current.validate();
      const isValid = await trigger();
      if (!isAddPaymentMethodValid || !isValid) {
        return;
      }
      await addPaymentMethodFormRef.current.submitAddPaymentMethod();
    } else {
      if (billingDetailsOpen) {
        const isBillingDetailsValid = await billingDetailsFormRef?.current?.validate();
        const isValid = await trigger();
        if (!isBillingDetailsValid || !isValid) {
          return;
        }
      }
      formRef?.current?.requestSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '496px',
        },
      }}
    >
      <DialogTitle>
        Take a Payment
        <IconButton
          onClick={() => handleClose(false)}
          size="small"
          disableRipple
          data-testid={`${testIdPrefix}CloseButton`}
        >
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {isProcessPaymentLoading && <Loading message="Please wait while we process your payment..." />}
        <form onSubmit={handleSubmit(handleTakePayment)} ref={formRef}>
          <ControlledTextField
            name="amount"
            control={control as unknown as Control<FieldValues, object>}
            fullWidth
            label="Amount"
            type="number"
            error={errors.amount?.message != null}
            helperText={errors.amount?.message}
            sx={{ width: { xs: '50%', md: '30%' }, mb: 2 }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{mapCurrency(balance?.currency)}</InputAdornment>,
              inputProps: {
                step: 0.01,
              },
            }}
            testId={`${testIdPrefix}Amount`}
          />
          <ControlledTextField
            control={control as unknown as Control<FieldValues, object>}
            name="description"
            fullWidth
            label="Description"
            description="Optional. This will appear on the Customer Receipt"
            error={errors?.description?.message != null}
            helperText={errors?.description?.message}
            testId={`${testIdPrefix}Description`}
            sx={{ mb: 2 }}
          />
          <CustomerSelectorAutocomplete
            testId={testIdPrefix}
            handleChange={() => {}}
            defaultValue={customer}
            disabled={true}
            label="Customer"
          />
          <Box sx={{ mt: 2 }}>
            <CardSelector
              isLoading={isFetchingAdditionalPaymentMethodList}
              control={control as unknown as Control<FieldValues, object>}
              name="paymentMethodReference"
              label="Payment Method"
              options={additionalPaymentMethodListOptions}
              error={errors.paymentMethodReference != null}
              helperText={errors.paymentMethodReference?.message}
              requiredMessage="Payment method is required"
              popperWidth="300px"
              disabled={addPaymentMethodOpen}
              onChange={(value) => {
                handleChangePaymentMethod(value);
              }}
              getOptionDisabled={(option) => {
                const currentDate = new Date();
                return (
                  option.expirationYear < currentDate.getFullYear() ||
                  (option.expirationYear === currentDate.getFullYear() &&
                    option.expirationMonth < currentDate.getMonth())
                );
              }}
              testId={`${testIdPrefix}PaymentMethod`}
            />
          </Box>
          {addPaymentMethodOpen ? (
            <Box sx={{ mt: 5 }}>
              <PaymentProvider
                type="paymentPlans"
                memberId={customer.memberKey}
                testId={`${testIdPrefix}AddPaymentMethod`}
                onClose={() => setAddPaymentMethodOpen(false)}
                resetCreatePaymentMethodError={resetCreatePaymentMethodError}
                callback={handleAddPaymentMethod}
                setAddPaymentModalOpen={setAddPaymentMethodOpen}
                billingDetails={billingDetails}
                componentRef={addPaymentMethodFormRef}
              />
            </Box>
          ) : (
            <>
              <Button
                onClick={() => {
                  setAddPaymentMethodOpen(true);
                  setSelectedPaymentMethodReference(undefined);
                }}
                variant="text"
                startIcon={<AddIcon />}
                data-testid={`${testIdPrefix}AddPaymentMethodButton`}
                sx={{ mt: 0.5 }}
              >
                Add New
              </Button>
              <Box my={3}>
                <SectionTitle>Billing to</SectionTitle>
                {!billingDetailsOpen ? (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1">
                      {`${billingDetails?.firstName || ''} ${billingDetails?.lastName || ''}`}
                    </Typography>
                    <Typography variant="caption">{billingDetails?.email || ''}</Typography>
                    <Typography variant="caption">{formatAddress(billingDetails?.billingAddress)}</Typography>
                    <Button
                      onClick={() => setBillingDetailsOpen(true)}
                      variant="text"
                      data-testid={`${testIdPrefix}ChangeBillingDetailsButton`}
                      sx={{ pl: 0, maxWidth: '156px' }}
                    >
                      Change Billing Details
                    </Button>
                  </Box>
                ) : (
                  <BillingDetailsForm
                    initialDetails={billingDetails}
                    componentRef={billingDetailsFormRef}
                    testId={`${testIdPrefix}BillingDetailsForm`}
                  />
                )}
              </Box>
            </>
          )}
          <Accordion
            elevation={0}
            sx={{
              mt: 2,
              '&::before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />} sx={{ px: 0 }}>
              <SectionTitle>Notes</SectionTitle>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="notes"
                fullWidth
                multiline
                minRows="5"
                label="Payment Notes"
                error={errors?.notes?.message != null}
                helperText={errors?.notes?.message}
                testId={`${testIdPrefix}Notes`}
              />
            </AccordionDetails>
          </Accordion>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose(false)} data-testid={`${testIdPrefix}CancelButton`}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={handleSubmitTakePayment}
          data-testid={`${testIdPrefix}SubmitButton`}
        >
          Submit Payment
        </Button>
      </DialogActions>
    </Dialog>
  );
};
