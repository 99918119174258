import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  DialogContentText,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AssetRedeemRequest } from '@one/api-models/lib/Admin/Members/Asset/Request/AssetRedeemRequest';
import { AssetReinstateRequest } from '@one/api-models/lib/Admin/Members/Asset/Request/AssetReinstateRequest';
import { AssetRedeemResponse } from '@one/api-models/lib/Admin/Members/Asset/Response/AssetRedeemResponse';
import { AssetReinstateResponse } from '@one/api-models/lib/Admin/Members/Asset/Response/AssetReinstateResponse';
import { Certificate } from '@one/api-models/lib/Membership/Account/Certificate';

import { ApiError } from 'apiAccess/api-client';
import { ConfirmationDialog } from 'components/_common/ConfirmationDialog';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { Brand } from 'models/Brand';
import {
  CertificateAccommodationProductTypes,
  CertificateCruiseProductTypes,
  CertificateExperienceProductTypes,
} from 'models/customers/CertificateProductTypes';
import { selectActiveBrand } from 'slices/applicationDataSlice';

import { getAssetOriginName } from './helpers/walletHelpers';
import { CertificateDetailsDialog } from './CertificateDetailsDialog';
import { CertificateTypeIcon } from './CertificateTypeIcon';
import OBOWithCertificateButton from './OBOWithCertificateButton';

const useStyles = makeStyles(() => ({
  redeemButton: {
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '16px',
    lineHeight: '1.4rem',
  },
}));

interface CertificatesProp {
  certificates: Certificate[];
  onRedemptionSuccess: () => void;
  isLoading: boolean;
  memberKey: string;
  testId: string;
}

export const Certificates: React.FC<CertificatesProp> = ({
  certificates,
  memberKey,
  testId,
  onRedemptionSuccess,
  isLoading,
}: CertificatesProp) => {
  const { showMessage, apiErrorHandler } = useToastMessage();
  const { api } = useApiHelpers();
  const classes = useStyles() as any;

  const [redemptionDialogOpen, setRedemptionDialogOpen] = useState<boolean>(false);
  const [redemptionDialogLoading, setRedemptionDialogLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Certificate | undefined>(undefined);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState<boolean>(false);
  const [isLoadingObo, setIsLoadingObo] = useState<boolean>(false);

  const { hasPermission } = useAppHelpers();
  const activeBrand = useSelector(selectActiveBrand);
  const { formatDate } = useFormat();

  const hasManagePermission = hasPermission(['Customers.Service.Asset:Manage']);
  const hasOBOPermission = hasPermission(['Customers:OnBehalfOf']);

  const [reinstateCertificateDialog, setReinstateCertificateDialog] = useState<boolean>(false);
  const [reinstateCertificateDialogLoading, setReinstateCertificateDialogLoading] = useState<boolean>(false);

  const handleCertificateRedemptionClick = (event: React.MouseEvent<HTMLButtonElement>, certificate: Certificate) => {
    event.stopPropagation();
    setSelectedItem(certificate);
    setRedemptionDialogOpen(true);
  };

  const handleReinstateCertificateClick = (event: React.MouseEvent<HTMLButtonElement>, certificate: Certificate) => {
    event.stopPropagation();
    setSelectedItem(certificate);
    setReinstateCertificateDialog(true);
  };

  const redemptionMutation = useMutation<AssetRedeemResponse, ApiError, AssetRedeemRequest, unknown>(
    async (request) => {
      return await api.members.redeemAsset(request);
    },
    {
      onSuccess: () => {
        onRedemptionSuccess();
        showMessage('Certificate redeemed successfully', 'success');
      },
      onError: apiErrorHandler,
      onSettled: () => {
        setSelectedItem(undefined);
        setRedemptionDialogLoading(false);
        setRedemptionDialogOpen(false);
      },
    },
  );
  const reinstateMutation = useMutation<AssetReinstateResponse, ApiError, AssetReinstateRequest, unknown>(
    async (request) => {
      return await api.members.reinstateAsset(request);
    },
    {
      onSuccess: () => {
        onRedemptionSuccess();
        showMessage('Certificate reinstated successfully', 'success');
      },
      onError: apiErrorHandler,
      onSettled: () => {
        setSelectedItem(undefined);
        setReinstateCertificateDialogLoading(false);
        setReinstateCertificateDialog(false);
      },
    },
  );

  const handleReinstateAction = (isConfirmed: boolean) => {
    if (isConfirmed && selectedItem) {
      setReinstateCertificateDialogLoading(true);
      reinstateMutation.mutate({
        memberKey,
        serialNumber: selectedItem.serialNumber,
      });
    } else {
      setReinstateCertificateDialog(false);
      setSelectedItem(undefined);
    }
  };

  const handleRedemptionAction = (isConfirmed: boolean) => {
    if (isConfirmed && selectedItem) {
      setRedemptionDialogLoading(true);
      redemptionMutation.mutate({
        memberKey,
        serialNumber: selectedItem.serialNumber,
      });
    } else {
      setRedemptionDialogOpen(false);
      setSelectedItem(undefined);
    }
  };

  const handleCertificateClick = (certificate: Certificate) => {
    setSelectedItem(certificate);
    setDetailsDialogOpen(true);
  };

  const renderRedeemedOrdersList = () => {
    const redeemedOrders = selectedItem?.redeemedOnOrders;

    if (!redeemedOrders || redeemedOrders?.length === 0) {
      return;
    }

    return (
      redeemedOrders && (
        <>
          <DialogContentText id="alert-dialog-description" sx={{ mt: 1 }}>
            Orders:
          </DialogContentText>
          {redeemedOrders?.map((order, index) => (
            <DialogContentText id="alert-dialog-description" key={index}>
              #{order}
            </DialogContentText>
          ))}
        </>
      )
    );
  };

  return (
    <Box>
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        Certificates
      </Typography>
      {isLoading || isLoadingObo ? (
        <Skeleton variant="text" width={200} />
      ) : (
        <Box>
          {certificates && certificates.length ? (
            <TableContainer component={Box}>
              <Table sx={{ width: '100%' }} size="small">
                <TableBody>
                  {certificates.map((cert) => (
                    <TableRow
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleCertificateClick(cert)}
                      key={`${cert.id}`}
                    >
                      <TableCell sx={{ borderBottom: '0', p: 1, pr: 0 }}>
                        <Tooltip title={cert.productTypes?.join(', ') || ''}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              width: { xs: 'auto', sm: '40px' },
                              gap: 0.5,
                              alignItems: 'baseline',
                            }}
                          >
                            {cert.productTypes &&
                              cert.productTypes.map((productType, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    display: 'block',
                                    width: '16px',
                                    height: '22px',
                                    opacity: 0.6,
                                  }}
                                >
                                  <CertificateTypeIcon type={productType} />
                                </Box>
                              ))}
                          </Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: '0',
                          p: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          '& > :not(:first-of-type)': {
                            fontSize: '12px',
                          },
                        }}
                      >
                        <Typography>{`[${cert.serialNumber}] ${cert.name}`}</Typography>
                        {cert?.assetOrigin ? (
                          <Typography>
                            {activeBrand?.key === Brand.FutureCruisePackagesUk ? 'Collection: ' : 'Package: '}
                            {getAssetOriginName(cert)}
                          </Typography>
                        ) : null}
                        {cert?.redeemedOn
                          ? cert?.redeemedOnOrders.map((order) => <Typography>#{order}</Typography>)
                          : null}
                        {cert?.activeFrom ? (
                          <Typography>
                            Active from: {formatDate(new Date(cert.activeFrom), true, 'dd MMM yyyy')}
                          </Typography>
                        ) : null}
                        {cert?.expiresOn ? (
                          <Typography>
                            {cert.productTypes?.some(
                              (type) =>
                                Object.values(CertificateAccommodationProductTypes).includes(type) ||
                                Object.values(CertificateCruiseProductTypes).includes(type) ||
                                Object.values(CertificateExperienceProductTypes).includes(type),
                            )
                              ? 'Book by: '
                              : 'Expires on: '}
                            {formatDate(new Date(cert.expiresOn), true, 'dd MMM yyyy')}
                          </Typography>
                        ) : null}
                        {cert.productTypes?.some((type) =>
                          Object.values(CertificateCruiseProductTypes).includes(type),
                        ) &&
                          cert.restrictions?.SailBy && (
                            <Typography>
                              Sail by: {formatDate(new Date(cert.restrictions?.SailBy), true, 'dd MMM yyyy')}
                            </Typography>
                          )}
                        {cert.productTypes?.some((type) =>
                          Object.values(CertificateAccommodationProductTypes).includes(type),
                        ) &&
                          cert.restrictions?.TravelBy && (
                            <Typography>
                              Travel by: {formatDate(new Date(cert.restrictions?.TravelBy), true, 'dd MMM yyyy')}
                            </Typography>
                          )}
                        {cert.productTypes?.some((type) =>
                          Object.values(CertificateExperienceProductTypes).includes(type),
                        ) &&
                          cert.restrictions?.UseBy && (
                            <Typography>
                              Use by: {formatDate(new Date(cert.restrictions?.UseBy), true, 'dd MMM yyyy')}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell sx={{ borderBottom: '0', p: 0, pr: 1 }}>
                        {hasManagePermission ? (
                          <Button
                            className={classes.redeemButton}
                            variant="outlined"
                            color={cert?.isReinstatementAllowed && cert?.redeemedOn ? 'success' : 'primary'}
                            onClick={(event) =>
                              cert.isReinstatementAllowed && cert.redeemedOn
                                ? handleReinstateCertificateClick(event, cert)
                                : handleCertificateRedemptionClick(event, cert)
                            }
                            disabled={!cert.isRedeemable && !cert.isReinstatementAllowed}
                            fullWidth
                            data-testid={`${testId}ActionButton`}
                          >
                            {cert.isReinstatementAllowed && cert.redeemedOn
                              ? 'Reinstate'
                              : cert.redeemedOn && !cert.isReinstatementAllowed
                              ? 'Redeemed'
                              : 'Redeem'}
                          </Button>
                        ) : null}
                        {hasOBOPermission &&
                        !hasManagePermission &&
                        cert.isBrowsable &&
                        cert.productTypes &&
                        cert.productTypes?.length &&
                        cert.serialNumber ? (
                          <OBOWithCertificateButton
                            options={cert.productTypes}
                            serialNumber={cert.serialNumber}
                            memberKey={memberKey}
                            redeemedOn={cert.redeemedOn}
                            testId={testId}
                            setIsLoadingObo={setIsLoadingObo}
                          />
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">No certificates available</Typography>
          )}
        </Box>
      )}

      <ConfirmationDialog
        open={redemptionDialogOpen}
        isLoading={redemptionDialogLoading}
        title="Redeem Certificate"
        question="This action will redeem the selected certificate of this member."
        confirmButton="Redeem"
        abortButton="Cancel"
        onClose={handleRedemptionAction}
        testId={`${testId}RedeemDialog`}
      />
      <ConfirmationDialog
        open={reinstateCertificateDialog}
        isLoading={reinstateCertificateDialogLoading}
        title="Reinstate Certificate"
        question="This action will reinstate the selected certificate of this member."
        confirmButton="Reinstate"
        abortButton="Cancel"
        onClose={handleReinstateAction}
        testId={`${testId}ReinstateDialog`}
      >
        {renderRedeemedOrdersList()}
      </ConfirmationDialog>

      <CertificateDetailsDialog
        open={detailsDialogOpen}
        onClose={() => {
          setDetailsDialogOpen(false);
          setSelectedItem(undefined);
        }}
        certificate={selectedItem}
        testId={`${testId}DetailsDialog`}
      />
    </Box>
  );
};
