import { GridFilterModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

interface SalesReservationListData {
  allBrandsEnabled: boolean;
  filtersModel: GridFilterModel;
}

const initialState: SalesReservationListData = {
  allBrandsEnabled: false,
  filtersModel: { items: [] },
};

const salesReservationListDataSlice = createSlice({
  name: 'salesReservationListData',
  initialState,
  reducers: {
    setAllBrandsEnabled: (state, action: PayloadAction<boolean>) => {
      state.allBrandsEnabled = action.payload;
    },
    setFiltersModel: (state, action: PayloadAction<GridFilterModel>) => {
      state.filtersModel = action.payload;
    },
  },
});

export const { setAllBrandsEnabled, setFiltersModel } = salesReservationListDataSlice.actions;

export const selectAllBrandsEnabled = (state: RootState) => state.salesReservationListData.allBrandsEnabled;
export const selectFiltersModel = (state: RootState) => state.salesReservationListData.filtersModel;

export default salesReservationListDataSlice.reducer;
