import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { RowAction } from 'components/_common/DataGrid/RowAction';
import { useFormat } from 'components/hooks/useFormat';

import { CodeSnippet } from './CodeSnippet';

export const DataGridStyleGuide = () => {
  const { formatDate, formatCurrency } = useFormat();

  const agents = [
    {
      id: 1,
      name: 'Dummy User',
      email: 'dummy.user@onecompany.com',
      amount: 2500,
      lastLoginDate: new Date(),
    },
    {
      id: 2,
      name: 'Test User',
      email: 'test.user@onecompany.com',
      amount: 150,
      lastLoginDate: new Date(),
    },
    {
      id: 3,
      name: 'Just Demo',
      email: 'just.demo@onecompany.com',
      amount: 12300,
      lastLoginDate: new Date(),
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      sortable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      sortable: true,
    },
    {
      field: 'amount',
      headerName: 'amount',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      flex: 0.5,
      renderCell: (params: any) => <>{formatCurrency(params?.row?.amount, 'USD')}</>,
    },
    {
      field: 'lastLoginDate',
      headerName: 'Last Login',
      flex: 1,
      renderCell: (params: any) => (
        <>{formatDate(new Date(params?.row?.lastLoginDate), true, 'MMM dd, yyyy hh:mm aa')}</>
      ),
    },
    {
      field: 'action',
      headerName: '',
      flex: 0.5,
      align: 'right',
      renderCell: (param) => (
        <RowAction
          key={param.row.id}
          cellParam={param}
          actions={[
            {
              label: 'Action 1',
              actionHandle: () => {},
            },
            {
              label: 'Action 2',
              actionHandle: () => {},
            },
          ]}
          testId="Action"
        />
      ),
    },
  ];

  return (
    <Box py={3}>
      <Typography variant="h6" pb={1}>
        DataGird
      </Typography>
      <DataGrid
        rows={agents.map((item: any) => ({ ...item }))}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 5,
            },
          },
        }}
      />

      <Typography variant="subtitle2">Header</Typography>
      <CodeSnippet>
        <Box>
          font-family: -apple-system,-system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;
        </Box>
        <Box>font-size: 11px;</Box>
        <Box>font-weight: 500;</Box>
        <Box>text-transform: 'uppercase';</Box>
        <Box>max-height: 30px;</Box>
      </CodeSnippet>

      <Typography variant="subtitle2">Rows</Typography>
      <CodeSnippet>
        <Box>
          font-family: -apple-system,-system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;
        </Box>
        <Box>font-size: 14px;</Box>
        <Box>font-weight: 400;</Box>
        <Box>max-height: 60px;</Box>
      </CodeSnippet>
    </Box>
  );
};
