import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Alert, Box, Button } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid-pro';
import { PromoCodeDefinition } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinition';
import { CreateRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/CreateRequest';
import { ListRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/ListRequest';
import { CreateResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/CreateResponse';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { PromotionQualifier } from '@one/api-models/lib/Campaign/Promotion/PromotionQualifier';

import { ApiError } from 'apiAccess/api-client';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { PromoCodeDefinitionStatus } from 'components/views/promoCodes/PromoCodeDefinitionStatus';
import { promoCodeDefinitionTypeOptions } from 'components/views/promoCodes/promoCodeHelpers';
import { selectActiveBrand } from 'slices/applicationDataSlice';

import { AddPromotionPromoCodeDialog } from './AddPromotionPromoCodeDialog';

interface PromotionPromoCodesViewProps {
  promotion: Promotion;
  testId: string;
  handleRemoveQualifier: (id: number) => Promise<void>;
  handleAddQualifier: (item: PromotionQualifier) => Promise<void>;
}

const defaultPageSize = 25;

export const PromotionPromoCodesView = ({ promotion, testId }: PromotionPromoCodesViewProps) => {
  const [addPromoCodeOpen, setAddPromoCodeOpen] = useState<boolean>(false);
  const [promoCodes, setPromoCodes] = useState<PromoCodeDefinition[]>([]);
  const [page, setPage] = useState(0);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const { formatDate } = useFormat();
  const navigate = useNavigate();
  const { api } = useApiHelpers();
  const { apiErrorHandler, showMessage } = useToastMessage();
  const isLoading = useIsMutating({ mutationKey: 'promoCodesMutation' }) > 0;
  const createIsLoading = useIsMutating({ mutationKey: 'createPromoCodeDefMutation' }) > 0;
  const activeBrand = useSelector(selectActiveBrand);

  useEffect(() => {
    loadPromoCodes(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadPromoCodes = async (page: number) => {
    await promoCodesMutation.mutateAsync({
      promotionIds: [promotion.id],
      brandKey: activeBrand?.key,
      listHandling: {
        skip: page * defaultPageSize,
        take: defaultPageSize,
        ascending: true,
      },
    } as ListRequest);
  };
  const promoCodesMutation = useMutation<any, ApiError, ListRequest, unknown>(
    (request) => api.promoCodeDefinition.load(request),
    {
      mutationKey: 'promoCodesMutation',
      onSuccess: (value: any) => {
        setPromoCodes(value?.promoCodeDefinitions?.items as any);
        setTotalRowsCount(value?.promoCodeDefinitions?.itemCount);
      },
      onError: apiErrorHandler,
    },
  );

  const handleRowClick = (promoCode: PromoCodeDefinition) => {
    navigate(
      `/marketing/promotions/${promotion.id}/promo-codes/${promoCode.id}/%2Fmarketing%2Fpromotions%2F${promotion.id}`,
    );
  };

  const createPromoCodeDefMutation = useMutation<CreateResponse, ApiError, CreateRequest, unknown>(
    (request) => api.promoCodeDefinition.create(request),
    {
      mutationKey: 'createPromoCodeDefMutation',
      onSuccess: (value: CreateResponse) => {
        if (value) {
          showMessage('Promo Code Definition added successfully.', 'success', false);
          loadPromoCodes(0);
        }
      },
      onError: apiErrorHandler,
    },
  );

  const handleCreatePromoCodeDefSubmit = async (data: PromoCodeDefinition) => {
    await createPromoCodeDefMutation.mutateAsync({
      promoCodeDefinition: data,
      brandKey: activeBrand?.key ?? '',
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
    },
    {
      field: 'promoCode',
      headerName: 'Promo Code',
      sortable: false,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Promo Code Name',
      sortable: false,
      flex: 2,
    },
    {
      field: 'promoCodeDefinitionType',
      headerName: 'Promo Code Type',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <>{promoCodeDefinitionTypeOptions[params?.row?.promoCodeDefinitionType]?.label}</>,
    },
    {
      field: 'validFromDate',
      headerName: 'Valid From (UTC)',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <>{formatDate(params?.row?.validFromDate, false, 'MMM dd, yyyy') || ''}</>,
    },
    {
      field: 'validToDate',
      headerName: 'Valid Until (UTC)',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <>{formatDate(params?.row?.validToDate, false, 'MMM dd, yyyy') || ''}</>,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <PromoCodeDefinitionStatus status={params?.row?.status} />,
    },
  ];

  return (
    <Box>
      <LoadingScreen open={isLoading} message={'Loading...'} />
      <LoadingScreen open={createIsLoading} message={'Saving your changes...'} />
      {!isLoading && (
        <>
          {promoCodes && promoCodes.length > 0 ? (
            <DataGrid
              rows={promoCodes}
              columns={columns}
              rowHeight={40}
              onRowClick={(param: any, event: any) => handleRowClick(param.row as PromoCodeDefinition)}
              sx={{
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                },
              }}
              paginationMode="server"
              initialState={{
                pagination: {
                  paginationModel: {
                    page: page,
                    pageSize: defaultPageSize,
                  },
                },
              }}
              rowCount={totalRowsCount}
              onPaginationModelChange={(data: GridPaginationModel) => setPage(data.page)}
              hideFooter={totalRowsCount <= defaultPageSize}
            />
          ) : (
            <Alert severity="info" variant="outlined">
              No promo codes available.
            </Alert>
          )}
        </>
      )}
      <Box mt={3}>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setAddPromoCodeOpen(true)}
          data-testid={`${testId}AddPromoCodeButton`}
        >
          Add Promo Code
        </Button>
      </Box>

      <AddPromotionPromoCodeDialog
        promotion={promotion}
        open={addPromoCodeOpen}
        handleClose={() => setAddPromoCodeOpen(false)}
        handleSavePromoCodeDefinition={handleCreatePromoCodeDefSubmit}
        testId={`${testId}PromoCodeDialog`}
      />
    </Box>
  );
};
