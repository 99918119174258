import { Box, Typography } from '@mui/material';

interface Props {
  label: string;
  icon: React.ReactNode;
  title: string;
  titleBadge?: React.ReactNode;
  actions?: React.ReactNode;
}
export const PageHeader = ({ title, label, icon, titleBadge, actions }: Props) => {
  return (
    <Box display="flex" width="100%">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" alignItems="center" gap={1}>
          {icon}
          <Typography
            variant="body1"
            sx={{ color: '#697386', fontSize: '0.688rem', fontWeight: 500, textTransform: 'uppercase' }}
          >
            {label}
          </Typography>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={{ xs: 1, md: 4 }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h1" sx={{ fontSize: { xs: '1.375rem', xl: '1.75rem' }, color: '#414552' }}>
              {title}
            </Typography>
            {titleBadge}
          </Box>
          <Box display="flex" flexWrap={'wrap'} justifyContent={'flex-end'} alignItems={'end'} gap={1.5}>
            {actions}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
