import { Fragment } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import { PurchaseSummary } from '@one/api-models/lib/Admin/Sales/PurchaseSummary';
import { PurchaseSummaryItemComponentType } from '@one/api-models/lib/Admin/Sales/PurchaseSummaryItemComponentType';
import { PurchaseSummaryItemType } from '@one/api-models/lib/Admin/Sales/PurchaseSummaryItemType';
import { AirPurchaseSummaryItemComponent } from '@one/api-models/lib/Admin/Sales/Travel/Air/AirPurchaseSummaryItemComponent';
import { Traveler } from '@one/api-models/lib/Admin/Sales/Travel/Air/Traveler';

import { useFormat } from 'components/hooks/useFormat';
import { Tooltip } from 'styled/Tooltip';

import { AGE_BAND_TYPE } from '../../constants/orderValues';

interface Props {
  purchaseSummary: PurchaseSummary;
  travelers?: Traveler[];
}

const getAirPurchaseSummaryPricesBreakdown = (purchaseSummary: PurchaseSummary) => {
  const payAtCheckout = purchaseSummary.summaryItems.find(
    (i) => i.type === PurchaseSummaryItemType.PayDuringPurchaseFlow,
  );

  const components: AirPurchaseSummaryItemComponent[] = payAtCheckout
    ? (payAtCheckout.components as AirPurchaseSummaryItemComponent[])
    : [];

  const travelersPrice = components.filter(
    (component) => component.itemType === PurchaseSummaryItemComponentType.PricePerPassenger,
  );

  const travelerTaxes = components.filter(
    (component) => component.itemType === PurchaseSummaryItemComponentType.TaxesAndFeesPerPassenger,
  );

  const baggagesPrice = components.filter(
    (component) => component.itemType === PurchaseSummaryItemComponentType.BaggagePerPassenger,
  );

  return { travelersPrice, travelerTaxes, baggagesPrice };
};

export const AirOrderItemPriceDetails = ({ purchaseSummary, travelers }: Props) => {
  const { formatCurrency } = useFormat();

  const { travelersPrice, baggagesPrice } = getAirPurchaseSummaryPricesBreakdown(purchaseSummary);

  return (
    <>
      {travelers?.map((traveler, index) => {
        const totalPrice = travelersPrice.find((price) => price.travelerId === traveler.id);
        const baggagePrices = baggagesPrice.filter((price) => price.travelerId === traveler.id);

        return (
          <Fragment key={index}>
            {(totalPrice || baggagePrices.length > 0) && (
              <Box mb={1} key={traveler.id}>
                <Box key={traveler.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography fontSize="0.875rem" fontWeight={600} lineHeight={1.7}>
                    Traveler {traveler.id}: {AGE_BAND_TYPE[traveler.ageBandType]}
                  </Typography>
                </Box>

                {totalPrice && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography fontSize="0.875rem">{totalPrice.name}</Typography>
                    <Typography fontSize="0.875rem">
                      {formatCurrency(totalPrice?.value.amount, totalPrice?.value.currency, 2)}
                    </Typography>
                  </Box>
                )}

                {baggagePrices.length > 0 && (
                  <>
                    <Typography fontSize="0.875rem" mb={0.5}>
                      Baggages
                    </Typography>
                    {baggagePrices.map((price, index) => (
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', ml: 1 }} key={index}>
                        {price.description ? (
                          <Tooltip arrow title={price.description} fontSize="0.0775" maxWidth="900px">
                            <Typography fontSize="0.875rem">
                              {price.name} <InfoOutlinedIcon sx={{ fontSize: '0.875rem', mb: -0.3 }} />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography fontSize="0.875rem">{price.name}</Typography>
                        )}
                        <Typography fontSize="0.875rem">
                          {formatCurrency(price?.value.amount, price?.value.currency, 2)}
                        </Typography>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            )}
          </Fragment>
        );
      })}
    </>
  );
};
