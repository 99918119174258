import { Order } from '@one/api-models/lib/Admin/Sales/Order';
import { OrderListCriteria } from '@one/api-models/lib/Admin/Sales/OrderListCriteria';
import { OrderListResponse } from '@one/api-models/lib/Admin/Sales/OrderListResponse';
import { RetrieveOrderCriteria } from '@one/api-models/lib/Admin/Sales/RetrieveOrderCriteria';

import { IApiClient } from './api-client';

export interface IOrderAccess {
  loadOrderList(criteria: OrderListCriteria): Promise<OrderListResponse>;
  loadOrderDetails(criteria: RetrieveOrderCriteria): Promise<Order>;
}

export class OrderAccess implements IOrderAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  loadOrderList(criteria: OrderListCriteria): Promise<OrderListResponse> {
    return this.apiClient.call('sales/order/list', {
      ...criteria,
      $Type: OrderListCriteria.$type,
      cultureCode: 'en-US',
    });
  }
  loadOrderDetails(criteria: RetrieveOrderCriteria): Promise<Order> {
    return this.apiClient.call('sales/order/retrieve', {
      ...criteria,
      $Type: RetrieveOrderCriteria.$type,
    });
  }
}
