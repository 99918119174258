import { AgeBandType } from '@one/api-models/lib/Admin/Sales/Travel/Experience/AgeBandType';
import { Traveler } from '@one/api-models/lib/Admin/Sales/Travel/Experience/Traveler';

enum PassengerType {
  MULTIPLE_ADULT = 'Adults',
  MULTIPLE_SENIOR = 'Seniors',
  MULTIPLE_YOUTH = 'Youths',
  MULTIPLE_CHILD = 'Children',
  MULTIPLE_INFANT = 'Infants',
  MULTIPLE_TRAVELER = 'Travelers',
  SINGLE_ADULT = 'Adult',
  SINGLE_SENIOR = 'Senior',
  SINGLE_YOUTH = 'Youth',
  SINGLE_CHILD = 'Child',
  SINGLE_INFANT = 'Infant',
  SINGLE_TRAVELER = 'Traveler',
}

export const mapTravelersPerAgeBand = (travelers: Traveler[]) => {
  const mapTravelerPerAgeBand = travelers?.reduce((acc, traveler) => {
    acc[traveler.ageBandType] = (acc[traveler.ageBandType] || 0) + 1;
    return acc;
  }, {} as { [key in AgeBandType]?: number });

  const totalTravelersString =
    mapTravelerPerAgeBand &&
    Object.entries(mapTravelerPerAgeBand)
      .map(([key, travelerNumber]) => {
        const passengerKey = travelerNumber === 1 ? `SINGLE_${key.toUpperCase()}` : `MULTIPLE_${key.toUpperCase()}`;
        return `${travelerNumber} ${PassengerType[passengerKey as keyof typeof PassengerType]}`;
      })
      .join(', ');

  return totalTravelersString;
};
