import { FC } from 'react';

import { Backdrop, CircularProgress, Grid, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

interface PaymentBackdropLoadingProps {
  isCompleteWithNoPayment: boolean;
  isLoading: boolean;
  isSubmittingPayment: boolean;
  isLoadingConfiguration?: boolean;
  style?: SxProps<Theme>;
}

export const PaymentBackdropLoading: FC<PaymentBackdropLoadingProps> = ({
  isCompleteWithNoPayment,
  isLoading,
  isSubmittingPayment,
  isLoadingConfiguration,
  style,
}) => {
  return (
    <Backdrop
      open={isCompleteWithNoPayment || isLoading || !!isLoadingConfiguration}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, ...style }}
    >
      <Grid spacing={1} container direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <CircularProgress color="inherit" data-testid="LoadingSpinner" />
        </Grid>
        {!isSubmittingPayment && !isLoadingConfiguration && (
          <Grid item>
            <Typography variant="h6">Loading...</Typography>
          </Grid>
        )}
        {isSubmittingPayment && (
          <>
            <Grid item>
              <Typography variant="h6">Purchase in progress...</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">Please do not close the browser.</Typography>
            </Grid>
          </>
        )}
        {isLoadingConfiguration && (
          <>
            <Grid item>
              <Typography variant="h6" sx={{ color: '#c62828' }}>
                Session Expired
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">Restarting payment...</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Backdrop>
  );
};
